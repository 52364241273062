import { css } from "aphrodite";
import { useResizeDetector } from "react-resize-detector";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import rawTokens from "../../styles/tokens/tokens-raw";
import tooltipTokens from "../../styles/tokens/tokens-tooltip";
import HomepageHeroImage from "./Images/HomepageHero.png";
import HomepageHeroAge from "./Images/HomepageHeroAge.png";
import HomepageHeroBrandSafety from "./Images/HomepageHeroBrandSafety.png";
import HomepageHeroChart from "./Images/HomepageHeroChart.png";
import HomepageHeroContacts from "./Images/HomepageHeroContacts.png";
import HomepageHeroCountries from "./Images/HomepageHeroCountries.png";
import HomepageHeroGender from "./Images/HomepageHeroGender.png";
import HomepageHeroHeader from "./Images/HomepageHeroHeader.png";
import HomepageHeroIncome from "./Images/HomepageHeroIncome.png";
import HomepageHeroListens from "./Images/HomepageHeroListens.png";
import HomepageHeroParentalStatus from "./Images/HomepageHeroParentalStatus.png";
import HomepageHeroPowerScore from "./Images/HomepageHeroPowerScore.png";
import HomepageHeroSidebar from "./Images/HomepageHeroSidebar.png";
import HomepageHeroSimilar from "./Images/HomepageHeroSimilar.png";
import HomepageHeroSocials from "./Images/HomepageHeroSocials.png";
import HomepageHeroSponsors from "./Images/HomepageHeroSponsors.png";

import generateTransition from "utils/generateTransition";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const TRIANGLE_HEIGHT = 16;

const baseStyles = {
  triangleBackground: {
    marginTop: "-16rem",
    height: `${TRIANGLE_HEIGHT}rem`,
    clipPath: "polygon(0 0, 0 100%, 100% 100%, 0 0)",

    background: rawTokens.raw.color.violet.l97,
  },
  insightsPreview: {
    background: "hsl(272deg 100% 97.06%)",

    border: `2px solid ${colours.white}`,

    borderRadius: "1.75rem",

    width: "94%",

    position: "absolute",
    top: "-14rem",

    maxWidth: "1400px",

    [ScreenSizes.lgAndAbove]: {
      top: "-18rem",
      minHeight: "28rem",
    },
  },
  insightsPreviewContainer: {
    background: rawTokens.raw.color.violet.l97,

    position: "relative",
    padding: "0 10%",

    marginTop: "-1px",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [ScreenSizes.lgAndAbove]: {
      paddingBottom: "3rem",
    },
  },
  image: {
    width: "100%",
    padding: "1rem",
    display: "block",

    [ScreenSizes.lgAndAbove]: {
      display: "none",
    },
  },
  imageResponsive: {
    width: "100%",
    height: "auto",
  },
  imageWrapper: {
    display: "flex",

    gap: "0.5rem",
  },
  imageContainer: {
    padding: "1rem",
    display: "none",

    [ScreenSizes.lgAndAbove]: {
      display: "block",
    },
  },
  imageBorderWhite: {
    padding: "0.5rem",
    borderRadius: 20,
    background: colours.white,

    width: "100%",
  },
  imageSocialsColumn: {
    display: "flex",
    flexDirection: "column",

    gap: "0.5rem",

    flexBasis: "21%",
  },
  imageReachContainer: {
    display: "flex",
    flexDirection: "column",

    gap: "0.5rem",

    flexBasis: "43%",
  },
  imagePowerScoreAndListensContainer: {
    display: "flex",
    gap: "0.5rem",

    width: "100%",
  },
  imageSidebar: {
    flexBasis: "13%",
  },
  imageHalf: {
    width: "calc(50% - 0.25rem)",
    objectFit: "contain",

    padding: "0.5rem",
    borderRadius: 20,
    background: colours.white,
  },
  stretchImage: {
    flex: 1,
  },
  imageDemographicContainer: {
    display: "flex",

    gap: "0.5rem",

    width: "100%",

    marginBottom: "0.5rem",
  },
  imageDemographic: {
    width: "calc(25% - 0.375rem)",
    borderRadius: 10,
  },
  containerHover: {
    transition: generateTransition({
      targets: ["transform"],
      speed: "200ms",
    }),

    ":hover": {
      transform: "scale(1.05)",
    },
    ":hover img": {
      transition: generateTransition({
        targets: ["box-shadow"],
        speed: "200ms",
      }),
      boxShadow: `0px 0px 8px 0px ${rawTokens.raw.color.violet.l70}`,
    },
  },
  imageHover: {
    transition: generateTransition({
      targets: ["transform", "box-shadow"],
      speed: "200ms",
    }),

    ":hover": {
      transform: "scale(1.05)",
      boxShadow: `0px 0px 8px 0px ${rawTokens.raw.color.violet.l70}`,
    },
  },
};

const tooltipColor = tooltipTokens.color.background.strong.default.default;

const TOOLTIP_STYLES = {
  tooltip: {
    ...gStyles.avalonBold,

    backgroundColor: tooltipColor,

    maxWidth: "18rem",
    padding: "0.75rem 1rem",

    textAlign: "center",

    fontSize: "1rem",

    transition: generateTransition({ target: "opacity", speed: "300ms" }),
  },
  contentDecor: {
    backgroundColor: tooltipColor,
  },
};

const HomepageInsightsPreview = (props) => {
  const { styles } = useStyles(baseStyles, props);

  const { height, ref } = useResizeDetector();

  const previewHeight = Math.max(height - TRIANGLE_HEIGHT * 16, 0);

  return (
    <>
      <div className={css(styles.triangleBackground)} />
      <div
        className={css(styles.insightsPreviewContainer)}
        style={{
          height: Number.isNaN(previewHeight) ? 398 : previewHeight,
        }}
      >
        <div className={css(styles.insightsPreview)} ref={ref}>
          <div className={css(styles.imageContainer)}>
            <img
              src={HomepageHeroHeader}
              alt="Podcast Insights Header"
              className={css(styles.imageResponsive)}
            />
            <div className={css(styles.imageWrapper)}>
              <BasicTooltip
                renderTooltip={() =>
                  "Access unrivaled data and intelligence on millions of podcasts—audience, trends, and performance, all in one place."
                }
                styles={TOOLTIP_STYLES}
              >
                {(tooltipProps) => (
                  <div
                    className={css(
                      styles.imageBorderWhite,
                      styles.imageSidebar,
                      styles.imageHover
                    )}
                    {...tooltipProps}
                  >
                    <img
                      src={HomepageHeroSidebar}
                      alt="Podcast Insights Sidebar"
                      className={css(styles.imageResponsive)}
                    />
                  </div>
                )}
              </BasicTooltip>
              <div className={css(styles.imageReachContainer)}>
                <div className={css(styles.imagePowerScoreAndListensContainer)}>
                  <BasicTooltip
                    renderTooltip={() =>
                      "A unique popularity and influence score powered by Podchaser's algorithm, analyzing 30+ key data points."
                    }
                    styles={TOOLTIP_STYLES}
                  >
                    {(tooltipProps) => (
                      <img
                        src={HomepageHeroPowerScore}
                        alt="Podcast Insights Power score"
                        className={css(
                          styles.imageResponsive,
                          styles.imageHalf,
                          styles.imageHover
                        )}
                        {...tooltipProps}
                      />
                    )}
                  </BasicTooltip>
                  <BasicTooltip
                    renderTooltip={() =>
                      "Estimated total listens across all episodes in the last 30 days—based on real data from millions of devices."
                    }
                    styles={TOOLTIP_STYLES}
                  >
                    {(tooltipProps) => (
                      <img
                        src={HomepageHeroListens}
                        alt="Podcast Insights Listens"
                        className={css(
                          styles.imageResponsive,
                          styles.imageHalf,
                          styles.imageHover
                        )}
                        {...tooltipProps}
                      />
                    )}
                  </BasicTooltip>
                </div>
                <BasicTooltip
                  renderTooltip={() =>
                    "Understand each podcast’s listener demographics."
                  }
                  styles={TOOLTIP_STYLES}
                >
                  {(tooltipProps) => (
                    <div
                      {...tooltipProps}
                      className={css(styles.containerHover)}
                    >
                      <div className={css(styles.imageDemographicContainer)}>
                        <img
                          src={HomepageHeroGender}
                          alt="Podcast Insights Gender"
                          className={css(
                            styles.imageBorderWhite,
                            styles.imageDemographic
                          )}
                        />
                        <img
                          src={HomepageHeroAge}
                          alt="Podcast Insights Age"
                          className={css(
                            styles.imageBorderWhite,
                            styles.imageDemographic
                          )}
                        />
                        <img
                          src={HomepageHeroIncome}
                          alt="Podcast Insights Income"
                          className={css(
                            styles.imageBorderWhite,
                            styles.imageDemographic
                          )}
                        />
                        <img
                          src={HomepageHeroParentalStatus}
                          alt="Podcast Insights Parental Status"
                          className={css(
                            styles.imageBorderWhite,
                            styles.imageDemographic
                          )}
                        />
                      </div>
                      <img
                        src={HomepageHeroCountries}
                        alt="Podcast Insights Countries"
                        className={css(styles.imageBorderWhite)}
                      />
                    </div>
                  )}
                </BasicTooltip>
                <BasicTooltip
                  renderTooltip={() =>
                    "Access 2M+ validated podcast contacts, including updated emails and submission forms."
                  }
                  styles={TOOLTIP_STYLES}
                >
                  {(tooltipProps) => (
                    <img
                      src={HomepageHeroContacts}
                      alt="Podcast Insights Contacts"
                      className={css(
                        styles.imageBorderWhite,
                        styles.imageHover
                      )}
                      {...tooltipProps}
                    />
                  )}
                </BasicTooltip>
              </div>
              <div className={css(styles.imageSocialsColumn)}>
                <BasicTooltip
                  renderTooltip={() =>
                    "Validated social handles and follower counts for 750k+ podcasts—updated weekly for accuracy."
                  }
                  styles={TOOLTIP_STYLES}
                >
                  {(tooltipProps) => (
                    <img
                      src={HomepageHeroSocials}
                      alt="Podcast Socials"
                      className={css(
                        styles.imageBorderWhite,
                        styles.imageHover
                      )}
                      {...tooltipProps}
                    />
                  )}
                </BasicTooltip>
                <BasicTooltip
                  renderTooltip={() =>
                    "View the latest brands sponsoring this podcast and spot new partnership opportunities."
                  }
                  styles={TOOLTIP_STYLES}
                >
                  {(tooltipProps) => (
                    <img
                      src={HomepageHeroSponsors}
                      alt="Podcast Sponsors"
                      className={css(
                        styles.imageBorderWhite,
                        styles.imageHover
                      )}
                      {...tooltipProps}
                    />
                  )}
                </BasicTooltip>
                <div
                  className={css(
                    styles.imageBorderWhite,
                    styles.stretchImage,
                    styles.imageHover
                  )}
                >
                  <BasicTooltip
                    renderTooltip={() =>
                      "Safety scores indicate the likelihood of sensitive content, powered by AI analysis of episode transcripts."
                    }
                    styles={TOOLTIP_STYLES}
                  >
                    {(tooltipProps) => (
                      <img
                        src={HomepageHeroBrandSafety}
                        alt="Podcast Insights Brand Safety"
                        className={css(styles.imageResponsive)}
                        {...tooltipProps}
                      />
                    )}
                  </BasicTooltip>
                </div>
              </div>
              <div className={css(styles.imageSocialsColumn)}>
                <BasicTooltip
                  renderTooltip={() =>
                    "View daily Apple Podcasts and Spotify rankings by country and category, plus 90-day performance trends."
                  }
                  styles={TOOLTIP_STYLES}
                >
                  {(tooltipProps) => (
                    <img
                      src={HomepageHeroChart}
                      alt="Podcast Insights Charts"
                      className={css(
                        styles.imageBorderWhite,
                        styles.imageHover
                      )}
                      {...tooltipProps}
                    />
                  )}
                </BasicTooltip>
                <div
                  className={css(
                    styles.imageBorderWhite,
                    styles.stretchImage,
                    styles.imageHover
                  )}
                >
                  <BasicTooltip
                    renderTooltip={() =>
                      "Fast-track research and find similar podcasts based on metadata, audience insights, and more."
                    }
                    styles={TOOLTIP_STYLES}
                  >
                    {(tooltipProps) => (
                      <img
                        src={HomepageHeroSimilar}
                        alt="Podcast Insights Similar Podcasts"
                        className={css(styles.imageResponsive)}
                        {...tooltipProps}
                      />
                    )}
                  </BasicTooltip>
                </div>
              </div>
            </div>
          </div>
          <img
            src={HomepageHeroImage}
            alt="Podcast Insights Example"
            className={css(styles.image)}
          />
        </div>
      </div>
    </>
  );
};

export default HomepageInsightsPreview;
