import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem",
    [ScreenSizes.xlAndAbove]: {
      width: "50%",
      marginBottom: 0,
    },
  },
  image: {
    width: "100%",
    objectFit: "contain",

    [ScreenSizes.lgAndAbove]: {
      objectFit: "contain",
    },
  },
};

const HomepageFeatureRowImage = (props) => {
  const { src, src2x, alt } = props;
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.imageContainer)}>
      <picture>
        <source
          media="(min-width: 1200px)"
          srcSet={`${src} 1x, ${src2x} 2x`}
          height="386"
          className={css(styles.image)}
        />
        <img src={src} className={css(styles.image)} alt={alt} />
      </picture>
    </div>
  );
};

export default HomepageFeatureRowImage;
