import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faAnalytics } from "@fortawesome/pro-regular-svg-icons/faAnalytics";
import { faClipboardCheck } from "@fortawesome/pro-regular-svg-icons/faClipboardCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import rawTokens from "../../styles/tokens/tokens-raw";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  title: {
    ...gStyles.avalonBold,

    margin: 0,
    fontSize: "2rem",
    marginBottom: "1.5rem",
    textAlign: "center",
    padding: "0 1rem",

    [ScreenSizes.lgAndAbove]: {
      fontSize: "2.5rem",
      marginBottom: "3rem",
    },

    [ScreenSizes.xlAndAbove]: {
      fontSize: "2.5rem",
    },
  },
  cardList: {
    display: "flex",
    gap: "0.75rem",
    flexDirection: "column",

    [ScreenSizes.lgAndAbove]: {
      flexDirection: "row",
    },
  },
  card: {
    padding: "2.5rem",
    border: "1px solid #ECECF0",

    borderRadius: 20,
    background: colours.white,

    flex: 1,
  },
  cardTitle: {
    ...gStyles.avalonBold,

    fontSize: "1.75rem",
    margin: 0,
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  cardDescription: {
    ...gStyles.fontRegular,
    fontSize: "1rem",
    margin: 0,

    lineHeight: "2rem",
    textAlign: "left",
  },
  container: {
    marginTop: "4rem",

    [ScreenSizes.lgAndAbove]: {
      marginTop: "4rem",
    },
  },
  icon: {
    width: "2rem",
    height: "2rem",
    color: rawTokens.raw.color.violet.l50,
  },
};

function Card(props) {
  const { icon, title, children } = props;
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.card)}>
      <FontAwesomeIcon icon={icon} className={css(styles.icon)} />
      <h3 className={css(styles.cardTitle)}>{title}</h3>
      {children}
    </div>
  );
}

const HomepageDescription = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.container)}>
      <h2 className={css(styles.title)}>
        Podchaser is the #1 podcast intelligence engine
      </h2>
      <div className={css(styles.cardList)}>
        <Card icon={faSearch} title="Discover Opportunities">
          <p className={css(styles.cardDescription)}>
            Access insights from 5M+ podcasts, including demographics, audience
            reach, and sponsorship trends.
          </p>
        </Card>
        <Card icon={faClipboardCheck} title="Plan with Precision">
          <p className={css(styles.cardDescription)}>
            Use our proprietary Power Score and contact database to refine your
            podcast strategy.
          </p>
        </Card>
        <Card icon={faAnalytics} title="Drive Results">
          <p className={css(styles.cardDescription)}>
            From discovery to execution, Podchaser makes it easy to connect,
            collaborate, and grow.
          </p>
        </Card>
      </div>
    </div>
  );
};

export default HomepageDescription;
