import { memo } from "react";
import { Link } from "react-router-dom";

import rawTokens from "../../styles/tokens/tokens-raw";
import HomepageApiAndProInfo from "./HomepageApiAndProInfo";
import HomepageBestPodcast from "./HomepageBestPodcast";
import HomepageCredits from "./HomepageCredits";
import HomepageDescription from "./HomepageDescription";
import HomepageDesktopHeader from "./HomepageDesktopHeader";
import HomepageInsightsPreview from "./HomepageInsightsPreview";
import HomepageNavLink from "./HomepageNavLink";
import HomepageProInsights from "./HomepageProInsights";
import HomepageTrack from "./HomepageTrack";
import HomepageWhatYouCanDo from "./HomepageWhatYouCanDo";

import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  contentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    background: rawTokens.raw.color.violet.l97,

    position: "relative",

    padding: "0 1.5rem",
    paddingTop: "1rem",
    paddingBottom: "2.5rem",

    [ScreenSizes.lgAndAbove]: {
      padding: "0 3rem",
      paddingTop: "1rem",
      paddingBottom: "2.5rem",
    },

    [ScreenSizes.xxlAndAbove]: {
      padding: "0 5rem",
      paddingTop: "1rem",
      paddingBottom: "4rem",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    flexDirection: "column",

    [ScreenSizes.lgAndAbove]: {
      maxWidth: "80rem",
      alignSelf: "center",
    },
  },
  startBrowsing: {
    background: rawTokens.raw.color.violet.l97,

    ...gStyles.avalonBold,

    fontSize: "1rem",
    padding: "2.5rem 0",
    textAlign: "center",
  },
  startBrowsingLink: {
    color: rawTokens.raw.color.violet.l40,

    ":hover": {
      cursor: "pointer",
    },
  },
};

const LoggedOutHomepage = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  const user = useLoggedInUser();

  return (
    <>
      <HomepageDesktopHeader user={user} />
      <HomepageInsightsPreview user={user} />
      <div className={css(styles.contentContainer)}>
        <div className={css(styles.wrapper)}>
          <HomepageNavLink />
          <HomepageDescription />
        </div>
      </div>
      <HomepageBestPodcast />
      <HomepageProInsights />
      <HomepageCredits />
      <HomepageTrack />
      <HomepageWhatYouCanDo />
      <HomepageApiAndProInfo />
      <p className={css(styles.startBrowsing)}>
        Or start browsing our extensive{" "}
        <Link to="/podcasts" className={css(styles.startBrowsingLink)}>
          podcast database
        </Link>
        .
      </p>
    </>
  );
};

LoggedOutHomepage.propTypes = {};

LoggedOutHomepage.defaultProps = {
  tabProps: {},
};

export default memo(LoggedOutHomepage);
