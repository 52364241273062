import { Fragment } from "react";

import StandardButton from "components/Buttons/StandardButton";

import generateTransition from "utils/generateTransition";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
  },
  menu: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  menuItem: {
    backgroundColor: "#fff",
    color: colours.bodyText,
    display: "flex",
    flex: 1,
    borderTop: "#dfe1e6 1px solid",
    overflow: "hidden",
    textAlign: "left",
    transition: generateTransition({ target: "visual", speed: "250ms" }),
  },
  menuItemHover: {
    backgroundColor: "#fdf2fb",
  },
  label: {
    ...gStyles.fontSemiBold,
    fontSize: "1em",
    display: "block",
  },
  subMenu: {
    position: "absolute",
    right: "100%",
    background: "white",
    height: "auto",
    borderRadius: "0.625rem",
    boxShadow: "0rem 0.375rem 0.688rem 0rem rgba(0, 0, 0, 0.15)",
  },
  subMenuItems: {
    ...gStyles.fontSemiBold,
    padding: "0.5rem 2rem",
    fontSize: "0.75rem",
  },
  subMenuHeader: {
    ...gStyles.avalonBold,
    fontSize: "0.8125rem",
    padding: "0.4rem 2rem",
  },
  break: {
    width: "100%",
    alignItems: "center",
    alignSelf: "center",
    margin: "0.25rem",
    height: "0.031rem",
    background: colours.lightishGreyBorder,
  },
  bolder: {
    fontWeight: "bolder",
  },
};

const SubMenu = (props) => {
  const { subMenu, parentIndex, downshiftProps, renderMenuItem, ariaLabel } =
    props;
  const { subMenuItems, heading, headerStyle } = subMenu;
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div
      className={css(styles.menuContainer, styles.subMenu)}
      data-testid="submenu-container"
    >
      <ul
        className={css(styles.menu)}
        {...downshiftProps.getMenuProps({
          "aria-labelledby": undefined,
          "aria-label": ariaLabel,
        })}
      >
        <li
          className={css(
            styles.menuItem,
            styles.label,
            styles.subMenuHeader,
            headerStyle
          )}
        >
          {heading}
        </li>
        {subMenuItems.map((item, i) =>
          item.type === "button" ? (
            <StandardButton
              label={item.label}
              variation="pink"
              onClick={() => {
                item.onClick();
                downshiftProps.closeMenu();
              }}
              customStyles={item.buttonStyles}
              flat
              key={item.dataId || `${i}.${parentIndex}`}
            />
          ) : item.id ? (
            item.label && (
              <Fragment key={item.dataId || `${i}.${parentIndex}`}>
                <li
                  className={css(
                    styles.menuItem,
                    downshiftProps.highlightedIndex === `${i}.${parentIndex}`
                      ? styles.menuItemHover
                      : null
                  )}
                  {...downshiftProps.getItemProps({
                    index: `${i}.${parentIndex}`,
                    item: item,
                  })}
                >
                  {renderMenuItem(item, `${i}.${parentIndex}`, true)}
                </li>
                {item.lineBreak && <div className={css(styles.break)}></div>}
              </Fragment>
            )
          ) : (
            item.label && (
              <Fragment key={`${i}.${parentIndex}`}>
                <li className={css(styles.menuItem, styles.subMenuItems)}>
                  {item.label}
                </li>
                {item.lineBreak && <div className={css(styles.break)}></div>}
              </Fragment>
            )
          )
        )}
      </ul>
    </div>
  );
};

export default SubMenu;
