import { commonModuleGroup } from "modules/groups";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";

import OAuthPrompt from "pages/OAuthConnect/OAuthPromptAsync";
import OAuthRoute from "pages/OAuthConnect/OAuthRoute";

import App from "./App";

import "../styles/css/flexboxgrid-base.css";
import "../styles/css/flexboxgrid-custom.css";
import "../styles/css/base.css";
import "../styles/css/colors.css";
import "../styles/css/tokens-raw.css";
import "../styles/css/tokens.css";
import "../styles/css/tokens-dark.css";
import "../styles/css/tokens-debug.css";

import "@fortawesome/fontawesome-svg-core/styles.css";
import "react-day-picker/dist/style.css";

const AppContainer = ({ nonce }) => {
  return (
    <Switch>
      <OAuthRoute
        path="/do-auth"
        component={<OAuthPrompt />}
        modules={[...commonModuleGroup]}
      />
      <App nonce={nonce} />
    </Switch>
  );
};

AppContainer.propTypes = {
  nonce: PropTypes.string,
};

AppContainer.defaultProps = {
  nonce: null,
};

export default AppContainer;
