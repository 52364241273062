import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faListAlt } from "@fortawesome/pro-regular-svg-icons/faListAlt";
import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons/faPaperPlane";
import { faWavePulse } from "@fortawesome/pro-regular-svg-icons/faWavePulse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import rawTokens from "../../styles/tokens/tokens-raw";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  container: {
    background: rawTokens.raw.color.violet.l97,

    padding: "4rem 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    ...gStyles.avalonBold,
    margin: 0,
    textAlign: "center",
    fontSize: "2rem",
    marginBottom: "1.5rem",
    padding: "0 1rem",

    [ScreenSizes.lgAndAbove]: {
      fontSize: "2.5rem",
      marginBottom: "3rem",
    },

    [ScreenSizes.xlAndAbove]: {
      fontSize: "2.5rem",
    },
  },
  cardList: {
    display: "flex",
    gap: "0.75rem",

    padding: "0 1.5rem",

    flexDirection: "column",
    flexWrap: "wrap",

    [ScreenSizes.lgAndAbove]: {
      flexDirection: "row",
      padding: "0 3rem",
    },

    [ScreenSizes.xxlAndAbove]: {
      padding: "0 5rem",
      maxWidth: "130rem",
    },
  },
  card: {
    paddingLeft: "2rem",
    paddingRight: "2rem",
    paddingBottom: "2rem",

    border: "1px solid #ECECF0",
    boxShadow: "0px 3px 12px 0px #31333805",

    borderRadius: 20,
    background: colours.white,

    flex: 1,

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "3rem",

    [ScreenSizes.lgAndAbove]: {
      flex: "1 0 47%",
      maxWidth: "50%",
    },
    [ScreenSizes.xlAndAbove]: {
      flex: "1 0 22%",
      maxWidth: "25%",
    },
    [createBreakpoint({ min: 1400 })]: {
      // minWidth: "28rem",
    },
  },
  cardPaddingTop: {
    paddingTop: "2rem",
  },
  cardTitle: {
    ...gStyles.avalonBold,

    fontSize: "1.25rem",
    margin: 0,
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  cardSubTitle: {
    ...gStyles.avalonBold,

    fontSize: "0.875rem",

    margin: 0,
    marginTop: "1rem",

    color: rawTokens.raw.color.violet.l50,
  },
  cardDescription: {
    ...gStyles.fontRegular,
    fontSize: "1rem",
    margin: 0,

    lineHeight: "2rem",
    textAlign: "left",
  },
  icon: {
    width: "2rem",
    height: "2rem",
    color: rawTokens.raw.color.violet.l50,
  },
};

const HomepageWhatYouCanDo = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.container)}>
      <h2 className={css(styles.title)}>See what you can do with Podchaser</h2>
      <div className={css(styles.cardList)}>
        <div className={css(styles.card, styles.cardPaddingTop)}>
          <div>
            <FontAwesomeIcon icon={faSearch} className={css(styles.icon)} />
            <p className={css(styles.cardSubTitle)}>Discovery & Insights</p>
            <h3 className={css(styles.cardTitle)}>
              Find podcasts that match your goals
            </h3>
            <p className={css(styles.cardDescription)}>
              Explore 5.5 million podcasts with powerful filters to find shows
              tailored to your audience, industry, or niche.
            </p>
          </div>
        </div>
        <div className={css(styles.card, styles.cardPaddingTop)}>
          <div>
            <FontAwesomeIcon icon={faListAlt} className={css(styles.icon)} />
            <p className={css(styles.cardSubTitle)}>Campaign Planning</p>
            <h3 className={css(styles.cardTitle)}>
              Simplify campaign building and execution
            </h3>
            <p className={css(styles.cardDescription)}>
              Build targeted lists, streamline sell-in, and export data easily.
              All the tools you need to execute campaigns efficiently.
            </p>
          </div>
        </div>
        <div className={css(styles.card, styles.cardPaddingTop)}>
          <div>
            <FontAwesomeIcon icon={faPaperPlane} className={css(styles.icon)} />
            <p className={css(styles.cardSubTitle)}>Contact Database</p>
            <h3 className={css(styles.cardTitle)}>
              Direct access to podcast contacts
            </h3>
            <p className={css(styles.cardDescription)}>
              Reach hosts, producers, and networks. Our contact database enables
              seamless outreach for sponsorships and collaborations.
            </p>
          </div>
        </div>
        <div className={css(styles.card, styles.cardPaddingTop)}>
          <div>
            <FontAwesomeIcon icon={faWavePulse} className={css(styles.icon)} />
            <p className={css(styles.cardSubTitle)}>Monitoring</p>
            <h3 className={css(styles.cardTitle)}>
              Track mentions, sponsorships, and credits
            </h3>
            <p className={css(styles.cardDescription)}>
              Stay on top of topics, sponsorships, and guest interviews to
              understand word-of-mouth and market trends.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageWhatYouCanDo;
