import tokens from "./tokens/tokens";
import chartTokens from "./tokens/tokens-chart";
import chipTokens from "./tokens/tokens-chip";
import formTokens from "./tokens/tokens-form";
import navigationTokens from "./tokens/tokens-navigation";
import rawTokens from "./tokens/tokens-raw";
import tableTokens from "./tokens/tokens-table";

// This file contains the colour variables used on the site.
// It features many unused or misused styles, and is in the process of being cleaned.
// Any new colours should be added via tokens (see Trainual docs) rather than being added to this file.
// Deprecated colours will be removed soon, so avoid using those for any reason!
// Other colours should also be avoided too, since tokens should be used instead.

// Note: When replacing colours, use this regex to find non-grey colours in files: #([a-fA-F0-9]{2})(?!\1)([a-fA-F0-9]{4})\b

export const rateColours = {
  one: { r: 250, g: 5, b: 104 },
  two: { r: 250, g: 5, b: 104 },
  three: { r: 250, g: 5, b: 104 },
  four: { r: 250, g: 5, b: 104 },
  five: { r: 250, g: 5, b: 104 },
};

// The main colours for our brand. You should not need to edit this set!
const baseColours = {
  primary: tokens.color.primary.default,
  secondary: tokens.color.secondary.default,
  positive: tokens.color.feedback.positive.default,
  negative: tokens.color.feedback.negative.default,
  accent: tokens.color.accent.default,
  actioned: tokens.color.actioned.default,
  selected: tokens.color.selected.default,
};

// Color definitions that have both a raw (non-generalised) key and a raw (rawTokens or hardcoded hash) value.
// References to raw colours like the ones below need to be updated to use semantic token definitions, and then removed from this file.
// This is partly a problem because raw colours will not update based on the theme in the future.
// Keys should refer to semantic uses, not raw value names or colour names.
const rawKeysAndValues = {
  // Greys

  oldSecondary: rawTokens.raw.color.neutral.l20,
  gunMetal: rawTokens.raw.color.neutral.l20, // "var(--color-neutral-d2)",
  nuetralD2: rawTokens.raw.color.neutral.l20, // "#332E38", // BrandSafety

  newDarkBlue: rawTokens.raw.color.neutral.l30, // "#404957",
  darkBlue: rawTokens.raw.color.neutral.l30, // "#364259", // creator wizard
  deeperGrey: rawTokens.raw.color.neutral.l30, // "#404957", // creator wizard
  oldSecondaryHighlight: rawTokens.raw.color.neutral.l30, // Refresh Button hover colour. "#2D416F",

  grey: rawTokens.raw.color.neutral.l40, // "var(--color-neutral-d4)",
  fadedDarkPurple: rawTokens.raw.color.neutral.l40, // "#524874", // Numbers on Alerts/Advertising page circles (1,2,3) // "#524874",

  semiDarkBlueGrey: rawTokens.raw.color.neutral.l50, // API CTA on entity sidebars // "#6b7793",
  midGrey: rawTokens.raw.color.neutral.l50, // "var(--color-neutral-d5)",
  greyishBlueDarkerFont: rawTokens.raw.color.neutral.l50, // "var(--color-neutral-d5)", // "#707785",
  oldSecondaryLighter: rawTokens.raw.color.neutral.l50, // "#354D83", //Various

  greyishBlueDarker: rawTokens.raw.color.neutral.l60, // "var(--color-neutral-d6)",
  blueGrey: rawTokens.raw.color.neutral.l60, // "#707D9A",
  newDarkGrey: rawTokens.raw.color.neutral.l60, // "#9098a4",

  lightishGrey: rawTokens.raw.color.neutral.l70, // "var(--color-neutral-d7)",
  silver: rawTokens.raw.color.neutral.l70, // "var(--color-neutral-d7)",
  nuetralD7: rawTokens.raw.color.neutral.l70, // "#B3ABBA", // BrandSafety
  blueGreyButton: rawTokens.raw.color.neutral.l70, // "#8b90b5",
  greyDot: rawTokens.raw.color.neutral.l70, // Insights sidebar nav dot // "#b3abba"

  midGreyBorder: rawTokens.raw.color.neutral.l80, // "#dbdbdb",
  greyishBlue: rawTokens.raw.color.neutral.l80, // "var(--color-neutral-d8)",
  lightGrey: rawTokens.raw.color.neutral.l80, // "var(--color-neutral-l2)",

  greyishBlueHighlight: rawTokens.raw.color.neutral.l90, // "var(--color-neutral-d85)",
  checkboxBackground: rawTokens.raw.color.neutral.l90, // "var(--color-neutral-d9)",
  newLightGreyBox: rawTokens.raw.color.neutral.l90, // Podcast/Episode FAQ Styles // "#e3e3e3",
  lightishGreyBorder: rawTokens.raw.color.neutral.l90, // "#e5e5e5",
  newMidDarkGrey: rawTokens.raw.color.neutral.l90, // "#e7e7e7",
  diabledLightGrey: rawTokens.raw.color.neutral.l90, // "#e8e8e8",
  lightGreyBorder: rawTokens.raw.color.neutral.l90, // "#e9e9e9",
  greyBorder: rawTokens.raw.color.neutral.l90, // "#ebebeb",
  lightPurple: rawTokens.raw.color.neutral.l90, // "#e6e0e9", // Pro Lead Form
  lightGreyishBlue: rawTokens.raw.color.neutral.l90, // "#e3e5eb",

  offWhite: rawTokens.raw.color.neutral.l94, // "#f4f4f4",

  newGrey: rawTokens.raw.color.neutral.l97, // "#F4F4F4",
  offWhiteLighter: rawTokens.raw.color.neutral.l97, // "#f9f9f9",
  greyBackground: rawTokens.raw.color.neutral.l97, // "#fcfcfc",
  brightIconGrey: rawTokens.raw.color.neutral.l97, // Creator wizard "#e8eaf8",
  neutral: rawTokens.raw.color.neutral.l97, // "#f7f6f9",
  newGreyBox: rawTokens.raw.color.neutral.l97, // "#f1f2f4",
  newLightGrey: rawTokens.raw.color.neutral.l97, // "#f6f7f9",

  white: rawTokens.raw.color.white, // "#fff",

  newGreen: rawTokens.raw.color.teal.l40, // "#34a83b", // Success messages, success icons, etc

  offWhiteBlue: tokens.color.selected.subtle.default, // "#f0f5ff", // Various uses

  header: rawTokens.raw.color.neutral.l50, // "#807788", // Various: NoteITemImage on user list Tables, X button on top search and Insights demographics item labels
};

// Color definitions that have a raw (non-semantic) key, but a semantic value.
// These definitions need to be updated to have a key that reflects the semantic usage.
const rawKeysAndSemanticValues = {
  // Yellow
  newYellow: tokens.color.accent.default, // "#fff06f",

  green: tokens.color.feedback.positive.default,

  boldPink: baseColours.primary, // "#FA0567",
};

// Color definitions that have a semantic key, or are used semantically on the app, but have a raw (rawTokens or hardcoded hash) value
// These need to be added as semantic tokens
const semanticKeysAndRawValues = {
  insightsItems: rawTokens.raw.color.violet.l50, // "#865fa3", // Insights Status icon and various Insights items

  cardSubtitleText: rawTokens.raw.color.neutral.l60, // "var(--color-neutral-d6)", // Subtle button background, card subtitles, claimed podcast card name

  stars: {
    1: "var(--color-rating-1)",
    2: "var(--color-rating-2)",
    3: "var(--color-rating-3)",
    4: "var(--color-rating-4)",
    5: "var(--color-rating-5)",
    empty: "#dcdfe5",
    filled: "var(--color-primary-d5)",
    estimated: "rgba(0,0,0,0.2)",
    lighter: {
      1: "#e66768", // TODO: use rateColours above (which is used by utils/colours.js)
      2: "#e6a86c",
      3: "#bebebe",
      4: "#e6de6c",
      5: "#c8e67b",
    },
  },

  menuHeader: rawTokens.raw.color.neutral.l40, // "#322e33", // menu

  actionedForeground: rawTokens.raw.color.white, // "#fff", // text on top of actioned button

  greyHover: rawTokens.raw.color.neutral.l97, // "#f7f7f7",
  closeHover: rawTokens.raw.color.neutral.l90, // "#edeaea", // Expanded section X close button hover

  //Aircheck
  darkGreyBackground: rawTokens.raw.color.neutral.l80, // "#d4ceda",
  lightGreyBackground: rawTokens.raw.color.neutral.l90, // "#e6e1ea",
  greySelected: rawTokens.raw.color.neutral.l94, // "#E3EEFF", // Grey pills/buttons on a selected row
  dynamicLightPurple: rawTokens.raw.color.violet.l90, // "#F0C9FF", // "Dynamic" tag on brand aircheck episode player

  darkGrey: rawTokens.raw.color.neutral.l40, // "#696071", // Various uses. 41 lightness

  placeHolder: rawTokens.raw.color.neutral.l50, // "#787878",

  pinkMenuHover: rawTokens.raw.color.rose.l97, // Hover on some dropdown menu items "#fdf2fb",

  // Green
  positiveDarker: rawTokens.raw.color.teal.l40, // StandardButton (green disabled) // "#8cb32c",
  positiveFocusBorder: rawTokens.raw.color.teal.l60, // StandardButton (green focus border) // "#9dbb4d",
  positiveInset: rawTokens.raw.color.teal.l60, // StandardButton (green active) "#ACD34B",
  positiveLighter: rawTokens.raw.color.green.l80, // Actioned green button (ValidButton) on moderation. "#DBECB2",

  // Purple
  secondaryD15: rawTokens.raw.color.violet.l30, // "linear-gradient(90deg, #2C0074 0%, #2C0052 51%, #34006E 100%)", // Side menu sub item hover
  secondaryPurple: rawTokens.raw.color.violet.l40, // "#5e2597", // Various
  secondaryD9: rawTokens.raw.color.violet.l90, // "#E9CCFF", // Homepage desktop header inactive tab color
  secondaryD4: tokens.color.data.categorical.category1.strong.default, // "#7D1AB7", // Homepage desktop header inactive tab color
  secondaryD6: rawTokens.raw.color.violet.l60, // "// Homepage desktop header inactive tab color

  positiveHighlight: rawTokens.raw.color.teal.l70, // "#C4DF7F",

  yellow: rawTokens.raw.color.yellow.default, // "#ffe500", BrandSafety TODO: Rename semantically
  orange: rawTokens.raw.color.orange.default, // "#F57E00", BrandSafety TODO: Rename semantically
  lightOrange: rawTokens.raw.color.orange.l94,

  fadedPurple: rawTokens.raw.color.neutral.l70, // "#9e87bc", // Single podcast - Advertise on podcast - episode card label

  verifiedGrey: rawTokens.raw.color.neutral.l40, // "var(--color-neutral-d6)", // Advertise on Podcasts landing - Verified Partners
  pill: rawTokens.raw.color.violet.l50, // "var(--color-secondary-d6)", // Advertise on Podcasts landing pills
  lightPink: rawTokens.raw.color.rose.l97, // "var(--color-secondary-d10)", // Ads

  lightLavender: rawTokens.raw.color.violet.l80, // "#ca96ff", // Insights contact item (AOR) TODO: Rename semantically

  highlightPurple: rawTokens.raw.color.violet.l80, // "#d99cfc", // Userlist header text elements - TODO: Rename semantically
  defaultInset: rawTokens.raw.color.violet.l20, // "#6C2791", // Userlist header active buttons - TODO: Rename semantically
};

// Color definitions that have a semantic key (good!) and a semantic value (good!)
// These are as good as these definitions get, but the actual references in each component
// should eventually be updated to point to the token rather than to this file.
// Some of these variables have been referenced incorrectly in components and still may need to be checked.
const semanticKeysAndSemanticValues = {
  awardGold: tokens.color.award.place1.light, // "#fff71a", // leaderboard awards
  awardGoldBorder: tokens.color.award.place1.mid, // "#ffce0c", // leaderboard awards
  awardSilver: tokens.color.award.place2.light, // "#d6dded", // leaderboard awards
  awardSilverBorder: tokens.color.award.place2.mid, // "#a6c2db", // leaderboard awards
  awardBronze: tokens.color.award.place3.light, // "#f0ccaf", // leaderboard awards
  awardBronzeBorder: tokens.color.award.place3.mid, // "#d38c60", // leaderboard awards

  badgeHighlight: tokens.color.award.place1.mid, // "#fcdb00", // Badges

  // Has Guests
  hasGuestsFalse: tokens.color.hasGuests.false.default, // "var(--color-neutral-l2)",
  hasGuestsFalseFaint: tokens.color.hasGuests.false.faint.default, // "var(--color-neutral-l2)",
  hasGuestsTrue: tokens.color.hasGuests.true.default, // baseColours.positive,
  hasGuestsTrueFaint: tokens.color.hasGuests.true.faint.default,

  // Active
  isActiveFalse: tokens.color.isActive.false.default,
  isActiveFalseFaint: tokens.color.isActive.false.faint.default,
  isActiveTrue: tokens.color.isActive.true.default,
  isActiveTrueFaint: tokens.color.isActive.true.faint.default,

  highlight: tokens.color.accent.default, // "#fff200",

  //Table - TODO: Rename semantically or move to tableStyles
  outsideBorder: tokens.color.border.default, // "#e6e1ea",
  insideBorder: tokens.color.border.default, // "#f0edf2",
  rowSelected: tableTokens.color.background.selected.default, // "#eef6ff",
  checkboxUnselected: formTokens.checkbox.color.background.default, // "#e6e1ea",
  checkboxSelected: formTokens.checkbox.color.background.selected.default, // "#417def",
  checkboxDisabled: formTokens.checkbox.color.background.disabled.default, // d8

  selectedLightBlue: tokens.color.selected.subtle.default, // "var(--color-selected-d95)",
  selectedDarkBlue: tokens.color.selected.default, // "var(--color-selected-d5)",

  transcriptBorder: tokens.color.border.default,
  borderGrey: tokens.color.border.default,

  mainHeaderBackground: tokens.color.background.impact.default.default, // "#570f8a",

  acceptGreen: tokens.color.feedback.positive.default, // "#309c37",
  declineRed: tokens.color.feedback.negative.default, // "#e54d4d",
  subtleGreen: tokens.color.feedback.positive.subtle.default, // "#309c37",

  // Charts
  tickerUp: tokens.color.feedback.positive.default, // "#20c892",
  tickerDown: tokens.color.feedback.negative.default, // "#ef4342",

  // Primary
  primaryLighter: tokens.color.primary.tint, // "var(--color-primary-highlight)",
  primaryHighlight: tokens.color.primary.tint, // "var(--color-primary-highlight)",
  primaryInset: tokens.color.primary.shade, // "var(--color-primary-lowlight)",
  primaryDarker: tokens.color.primary.shade, // "var(--color-primary-lowlight)",
  primaryTextBackground: tokens.color.primary.default, // baseColours.primary,
  primaryButton: tokens.color.primary.default, // "#ff0065",

  // Red
  newDarkRed: tokens.color.feedback.negative.default, // "#e96767",
  negativeLightDark: tokens.color.feedback.negative.shade, // "#d96d6e", // Negative StandardButton hover
  negativeLighter: tokens.color.feedback.negative.tint, // "#df8384", // Negative StandardButton :active, and actioned NegativeButton
  newLightRed: tokens.color.feedback.negative.subtle.default, // "#ffeeee",

  integrationStatusNotIntegratedLight:
    tokens.color.integrations.status.notIntegrated.light, // "var(--color-negative-d9)", // "#f0a8a8",
  integrationStatusNotIntegratedDark:
    tokens.color.integrations.status.notIntegrated.mid, // "var(--color-negative-d3)", // "#ad1f1f",

  integrationStatusIntegratedLight:
    tokens.color.integrations.status.integrated.light, // "var(--color-positive-d8)", // "#cae87d",
  integrationStatusIntegratedDark:
    tokens.color.integrations.status.integrated.mid, // "var(--color-positive-d3)", // "#607a1f",

  integrationStatusInProgressLight:
    tokens.color.integrations.status.inProgress.light, // "#facc9e",
  integrationStatusInProgressDark:
    tokens.color.integrations.status.inProgress.mid, // "#ad661f",

  integrationStatusPartialLight: tokens.color.integrations.status.partial.light, // "var(--color-positive-d8)",
  integrationStatusPartialDark: tokens.color.integrations.status.partial.mid, // "var(--color-positive-d3)",

  //Socials
  facebook: tokens.color.external.social.facebook.default.default, // "#4e70a8",
  youtube: tokens.color.external.social.youtube.default.default, // "#FF0000",
  twitter: tokens.color.external.social.x.default.default, // "#000",
  twitterHover: tokens.color.external.social.x.hover.default, // "#515151",
  linkedIn: tokens.color.external.social.linkedIn.default.default, // "#0762C8",
  twitch: tokens.color.external.social.twitch.default.default, // "#6441a5",
  twitterBlue: tokens.color.external.social.twitter.default.default, // "#32c5ff",

  titleGradient: tokens.color.text.display.vibrant.gradient, // "linear-gradient(90deg, #6801BD 0%, #A003ED 55%, #C600FF 100%)", // Advertise on Podcasts landing title text
  section2Gradient: tokens.color.background.impact.default.gradient, // "linear-gradient(90deg, #590293 0%, #67029F 55%, #7304A7 100%)", // Advertise on Podcasts landing background

  selectedBlue: tokens.color.selected.default, // "dodgerBlue",

  inProgress: tokens.color.integrations.status.inProgress.mid, // "#fac267",

  hasGuestsSelected: tokens.color.hasGuests.true.onSelected.default, // "#A1BDF7", // Has Guests icon on a selected row
  hasGuestsSelectedFalse: tokens.color.hasGuests.false.onSelected.default, // Has Guests (false) icon on a selected row

  selectedD95: tokens.color.selected.subtle.default, // "#E7EFFD",

  scaleBackground: chartTokens.progress.bar.color.background.default, // "#E6E1EA", // BrandSafety bar background & unused UserListView amountLeft background

  claimed: tokens.color.claimed.default, // "#cb36b5", // Claimed icon on some tables

  adRatePillBackground: chartTokens.period.pill.color.background.default, // "#F0F5FF",
  adRatePillborder: chartTokens.period.pill.color.border.default, // "#CACACA",

  disclaimer: tokens.color.text.disclaimer.default, // "#747474",

  directRecommendationBackground: tokens.color.background.comment.default, // "var(--color-neutral-l1)", // Direct Recommendation comment bubble
  reviewBubbleBackground: tokens.color.background.comment.default, // "#f0efff",

  roles: {
    default: tokens.color.roles.default.default, // "#7f4dce",
    host: tokens.color.roles.host.default, // "#d842e1",
    guest: tokens.color.roles.guest.default, // "#8176d1",
    characterName: tokens.color.roles.character.default,
  },

  cardCategoryHoverText: chipTokens.color.text.tag.hover.default, // rawTokens.raw.color.neutral.l30, // "#495466", // Category Capsules hover/focus state

  entitySidebarText: tokens.color.text.subtle.default, // rawTokens.raw.color.neutral.l40, // "#6B7790", // entity page sidebar subtle text
  entitySubtleText: tokens.color.text.subtle.onDark, // rawTokens.raw.color.violet.l90, // "#e0c7ea", // label text under header stats on user/creator profile

  subtleBodyText: tokens.color.text.subtle.default, // rawTokens.raw.color.neutral.l30, // "rgb(70, 80, 83)",

  textGrey: tokens.color.text.subtle.default, // "#9f9ea0", // Not Available text for missing contacts in table view. Also used in Insights. TODO: Needs a semantic name

  // Contacts
  highConfidence: tokens.color.confidence.high.default, // "#1EC892",
  moderateConfidence: tokens.color.confidence.moderate.default, // "#F8901F",

  brightYellow: tokens.color.feedback.experimental.default, // "#FFF200", // Beta Badge
  brightOrange: tokens.color.feedback.experimental.default, // "#FFD000", // Beta Badge TODO Combine with brightYellow and rename

  //Demographics Insights
  dataVisPrimary: tokens.color.data.sequential.primaryToFaded.step1.default, // "#FF1B7B", // rawTokens.raw.color.rose.l50
  dataVisSecondary: tokens.color.data.sequential.primaryToFaded.step3.default, // "#E84C8D", // 60
  dataVisTertiary: tokens.color.data.sequential.primaryToFaded.step5.default, // "#C57396",
  dataVisQuaternary: tokens.color.data.sequential.primaryToFaded.step7.default, // "#C29BC1", // 70
  dataVisQuinary: tokens.color.data.sequential.primaryToFaded.step9.default, // "#CCC1D9", // 80

  // Insights - Parents
  brightPink: tokens.color.data.categorical.category2.strong.default, // Insights parents. "#CB36B5",
  darkishBlue: tokens.color.data.categorical.category4.strong.default, // "#427CF0", // Insights Parents and many other uses. 60 lightness
  darkishBlueForeground: tokens.color.data.categorical.category4.subtle.default, // "#E2EBFF", // Insights Parents colour, 94 lightness
  fadedLightPurple: tokens.color.data.categorical.category2.subtle.default, // "#FDE8F9", // Insights Parents colour, 95 lightness

  // Inights - Gender
  darkPurple: tokens.color.data.categorical.category1.strong.default, // Insights Gender and some other uses. "#7D1AB7",
  darkPurpleForeground: tokens.color.data.categorical.category1.subtle.default, // Insights Gender. "#F5E9FC",
  brightBlue: tokens.color.data.categorical.category3.strong.default, // Insights gender. "#0AA9C8",
  fadedLightBlue: tokens.color.data.categorical.category3.subtle.default, // "#D2F5F9", // Insights Gender colour, 90 lightness

  veryLightBlue: tokens.color.selected.subtle.default, // Download Contacts multi select card item hover, "#CCDDFF", // 90 lightness

  brightLightGreen: tokens.color.feedback.positive.default, // "#1EC892", // Insights Income, Network Insights, Download Contacts modal // 45 lightness
  darkOrangeYellow: tokens.color.feedback.warning.default, // "#F8901F", // "aggregated from similar" icon on Insights. 55 lightness

  actionedHighlight: tokens.color.actioned.tint, // "var(--color-actioned-highlight)", // Only seems to be on StandardButton actioned focus state
  actionedLowlight: tokens.color.actioned.shade, // "var(--color-actioned-lowlight)", // StandardButton disabled pink active state

  notice: tokens.color.feedback.warning.default, // "#f8901f", // Insights help icon and warning

  InsightCardBoxShadow: tokens.elevation.boxShadow.raised, // "0 2px 4px rgb(154 160 185 / 7%), 0 6px 11px rgb(166 173 201 / 20%)",

  track: tokens.color.primary.subtle.onSubtle.default, // "#ffcce0", // Brand view "midCover" track background // Unplayed portion of episode track bar

  navPath: navigationTokens.stepBar.color.background.default, // "#f7f6f9", // Submenu nav bars on Insights pages
};

// Temporary colours to help with the transition and clean up of colours
const temporaryColours = {
  bodyText: tokens.color.text.body.default,
  subtleBodyText: tokens.color.text.subtle.default,

  bodyBackground: tokens.color.background.default,
};

// These colours are mostly unused, but have been left in case someone's current branch needs to use them.
const unusedColours = {
  // Unused variables
  purpleishPlaceholder: "#b19cc4", // Not even a correct value!
  defaultbutton: "#9b40bf", // Userlist
  noneAdTrack: "#ccc",
  radarGrey: "#838383",
  blueButton: "#439ad0",
  placeholderText: "#707785",

  neutralL1: rawTokens.raw.color.neutral.l94,
  neutralL2: rawTokens.raw.color.neutral.l90,
  neutralL3: rawTokens.raw.color.neutral.l80,
  neutralL4: rawTokens.raw.color.neutral.l70,
  neutralL5: rawTokens.raw.color.neutral.l60,
  newSecondaryD1: rawTokens.raw.color.violet.l10,
  midPurple: "var(--color-secondary-d4)",

  secondaryD10: rawTokens.raw.color.violet.l100, // // Homepage desktop header inactive tab color

  secondaryHighlight2: "#a06bf5",
  tertiaryInset: "#5c3d87",
  tertiaryMidDark: "#3f2c6c",
  tertiaryDarker: "#32235a",

  secondaryInset: "#25355B",
  secondaryLightDark: "#1D2B4D",
  secondaryMidDark: "#1B2747",
  secondaryDarker: "#1B2747",
  newGreyishPurple: "#DAABCF",

  buttonBorderGrey: "#DFE1E6",

  fadedWhiteOnNewSecondary: "#c6b3c8",

  reviewBubbleBackgroundHighlight: "#f6f5fd",
  negativeHighlight: "var(--color-negative-highlight)",
  negativeLowlight: "var(--color-negative-lowlight)",

  primaryFaded: "#ff669c",

  newPrimaryOpaqued: "#f7056780",

  buttonText: "var(--color-neutral-d2)",

  tertiary: rawTokens.raw.color.violet.l60,
  tertiaryLighter: "#8155a8", // Standard button hover (variation: tertiary)
  tertiaryDesaturated: "#58476c", // Standard button disabled (variation: tertiary)
  tertiaryLightDark: "#4f3579", // Standard button active (variation: tertiary)

  selectedSemiLightBlue: "var(--color-selected-d8)",

  validFocusBorder: "#9dbb4d", // Unused
  validDarker: "#8cb32c", // Unused
  validInset: "#ACD34B", // Unused
  validHighlight: "#C4DF7F", // Unused
  validLighter: "#DBECB2", // Unused

  richPurple: formTokens.field.shortcut.color.background.onImpact.default, // "#55109b",

  violet: formTokens.field.color.background.subtle.onImpact.default, // "#44047c", // Top search background

  actionedBoxShadow: "var(--box-shadow-actioned)",
  actionedSubtleBoxShadow: "var(--box-shadow-actioned-subtle)",

  red: rawTokens.raw.color.rose.l50, // "#f70567",

  //BarChart
  barGreen: rawTokens.raw.color.teal.l50, // "var(--color-positive-d5)", // Network Insights

  //Verified Banner
  icon: "#d9b3f9",
  descriptionText: "#8355aa",

  black: rawTokens.raw.color.black, // "#000",
  deepGrey: "yellow", // rawTokens.raw.color.neutral.l10, // "var(--color-neutral-d1)",
};

export const unsortedColours = {
  //political
  politicalLeftStrong: tokens.color.political.left.strong,
  politicalLeftSubtle: tokens.color.political.left.subtle,
  politicalLeftFaint: tokens.color.political.left.faint,
  politicalRightStrong: tokens.color.political.right.strong,
  politicalRightSubtle: tokens.color.political.right.subtle,
  politicalRightFaint: tokens.color.political.right.faint,
  politicalNeutralStrong: tokens.color.political.neutral.strong,
  politicalNeutralFaint: tokens.color.political.neutral.faint,
};

export const validColours = {
  ...baseColours,
  ...rawKeysAndValues,
  ...rawKeysAndSemanticValues,
  ...semanticKeysAndRawValues,
  ...semanticKeysAndSemanticValues,
  ...temporaryColours,
  ...unsortedColours,
};

const debugging = false;
let colours = {};

function missingPropertyWarning(name) {
  console.warn("Accessing missing colour variable:", name);
}

function deprecatedPropertyWarning(name, value) {
  console.warn("Accessing deprecated colour variable:", name, value);
}

if (debugging) {
  // Check that colours have been targeted properly
  Object.keys(validColours).forEach((key) => {
    if (typeof validColours[key] === "object") {
      if (validColours[key].value || validColours[key].default) {
        console.error(
          `Color "${key}"'s value is not a valid token. Check that its value is a token and not an object.`,
          validColours[key]
        );
      }
    }
  });

  // TODO: Once the colours file has been cleaned up this can be changed back to a normally-accessed object
  // This getter version is used for handling deprecated and missing colour access
  colours = new Proxy(
    {},
    {
      get(target, key) {
        // eslint-disable-next-line no-prototype-builtins
        if (validColours.hasOwnProperty(key)) {
          return validColours[key];
        }
        // eslint-disable-next-line no-prototype-builtins
        if (unusedColours.hasOwnProperty(key)) {
          deprecatedPropertyWarning(key, unusedColours[key]);
          return unusedColours[key];
        }
        missingPropertyWarning(key);
        return null;
      },
    }
  );
} else {
  // TODO
  colours = {
    ...validColours,
    ...unusedColours,
  };
}

export default colours;
