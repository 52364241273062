import { HomepageCheckList, HomepageCheckListEntry } from "./HomepageCheckList";
import {
  HomepageTextSubTitle,
  HomepageTextTitle,
  HomepageTextArea,
  HomepageFeatureRow,
} from "./HomepageFeatureRow";
import HomepageFeatureRowImage from "./HomepageFeatureRowImage";
import HomepageSubtleButton from "./HomepageSubtleButton";
import HomepageCreditsImage from "./Images/PodcastCreditsPreview.png";
import HomepageCreditsImage2x from "./Images/PodcastCreditsPreview@2x.png";

import sendGAEvent from "utils/sendGAEvent";

const HomepageCredits = (props) => {
  return (
    <HomepageFeatureRow>
      <HomepageFeatureRowImage
        src={HomepageCreditsImage}
        src2x={HomepageCreditsImage2x}
        alt="Host, Guests, and Crew Credits"
      />
      <HomepageTextArea>
        <HomepageTextSubTitle>
          Host, Guests, and Crew Credits
        </HomepageTextSubTitle>
        <HomepageTextTitle>See who is on which podcasts</HomepageTextTitle>
        <HomepageCheckList>
          <HomepageCheckListEntry
            title="Creator profiles"
            subTitle="Verify credentials and contributions to podcasting."
          />
          <HomepageCheckListEntry
            title="Credits"
            subTitle="Enhance discoverability, establish credibility, and streamline collaboration opportunities in the podcasting industry."
          />
          <HomepageCheckListEntry
            title="Add your own"
            subTitle="Showcase your contributions, build your personal brand, and connect with other podcasting professionals."
          />
        </HomepageCheckList>
        <HomepageSubtleButton
          label="Browse Credits"
          link="/creators"
          onClick={() => {
            sendGAEvent({
              action: "homepageButtonClick",
              label: "browseCredits",
              context: `Homepage`,
            });
          }}
        />
      </HomepageTextArea>
    </HomepageFeatureRow>
  );
};

export default HomepageCredits;
