import { Map } from "immutable";
import PropTypes from "prop-types";
import deepmerge from "ramda/src/mergeDeepRight";

import gStyles from "../../../styles/GenericStyles";
import NavMenuItem from "./NavMenuItem";
import useNavLinks from "./useNavLinks";

import { userHasPermission } from "utils/entity/user";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import HeaderStyles from "styles/HeaderStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  container: {
    background: "linear-gradient(136.47deg, #350162 2.45%, #6B06A6 100%)",

    zIndex: 15,
    width: "100%",
    transition: "ease-out 0.2s",
  },
  inner: {
    ...HeaderStyles.headerContent,
    maxWidth: "80rem",
    margin: "auto",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",

    [ScreenSizes.smOnly]: {
      ...HeaderStyles.headerContent[ScreenSizes.smOnly],
      paddingLeft: "0.25rem",
      paddingRight: "0.25rem",
    },
    [ScreenSizes.mdOnly]: {
      ...HeaderStyles.headerContent[ScreenSizes.mdOnly],
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
    [ScreenSizes.mdAndBelow]: {
      ...HeaderStyles.headerContent[ScreenSizes.mdAndBelow],
      flexDirection: "column",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  section: {
    margin: "0 0.5rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [ScreenSizes.smAndAbove]: {
      margin: "0 1rem",
    },
    [ScreenSizes.mdAndAbove]: {
      margin: "0 2rem",
    },
    [ScreenSizes.lgAndAbove]: { margin: 0, justifyContent: "flex-start" },
  },
  sectionLeft: {},
  sectionRight: {
    [ScreenSizes.mdAndBelow]: {
      display: "none",
    },
  },
};

const overrideStyles = {
  navItem: {
    fontSize: "1rem",
    ":hover": {
      textShadow: "none",
    },
  },
  navItemActive: {
    fontSize: "1rem",
  },
};

const leftStyles = {
  navItem: {
    fontSize: "1rem",
    ":hover": {
      textShadow: "none",
    },
  },
  navItemActive: {
    fontSize: "1rem",
  },
  navItemOuter: {
    ":nth-child(2)": {
      [ScreenSizes.lgAndAbove]: {
        marginRight: "1rem",
        ":hover": {
          background: "var(--color-secondary-d4)",
        },
      },

      [ScreenSizes.xlAndBelow]: {
        marginTop: "0.225rem",
        marginRight: "0.78rem",
      },
      [ScreenSizes.mdAndBelow]: {
        marginTop: "0.4rem",
      },
      [createBreakpoint({ min: 768, max: 1400 })]: {
        marginRight: "0.6rem",
        marginBottom: "0.7rem",
      },
    },
  },
};

const NavMenu = (props) => {
  const { user, navItemStyles: passedNavItemStyles = {} } = props;

  const { styles, css } = useStyles(baseStyles, props);

  const { isWindowSizeOrMore } = useWindowSize();

  const { leftItems, rightItems } = useNavLinks();

  const renderNavItems = (items, side) =>
    items.reduce((contents, item) => {
      if (!item.minScreenSize || isWindowSizeOrMore(item.minScreenSize)) {
        const navItemStyles = side === "left" ? leftStyles : overrideStyles;
        contents.push(
          <NavMenuItem
            styles={deepmerge(navItemStyles, passedNavItemStyles)}
            key={item.id}
            item={item}
          />
        );
      }

      return contents;
    }, []);

  const checkItemPermission = (item) =>
    (!item.permission || userHasPermission(user, item.permission)) &&
    (!item.noPermission || !userHasPermission(user, item.noPermission));

  return (
    <nav className={css(styles.container)}>
      <div className={css(styles.inner)}>
        <div className={css(styles.section, styles.sectionLeft)}>
          {renderNavItems(leftItems.filter(checkItemPermission), "left")}
        </div>
        <div className={css(styles.section, styles.sectionRight)}>
          {renderNavItems(rightItems.filter(checkItemPermission), "right")}
        </div>
      </div>
    </nav>
  );
};

NavMenu.propTypes = {
  user: PropTypes.instanceOf(Map),
};

NavMenu.defaultProps = {
  user: null,
};

export default NavMenu;
