import buttonTokens from "../../styles/tokens/tokens-button";
import rawTokens from "../../styles/tokens/tokens-raw";
import HomepageProButton from "./HomepageProButton";
import HomepageSubtleButton from "./HomepageSubtleButton";
import HomepageApiAndProInfoBackground from "./Images/HomepageApiAndProInfoBackground.png";
import HomepageApiAndProInfoBackgroundMobile from "./Images/HomepageApiAndProInfoBackgroundMobile.png";
import PodchaserApiLogo from "./Images/PodchaserApiLogo.png";
import PodchaserProLogo from "./Images/PodchaserProLogo.svg";

import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    backgroundColor: rawTokens.raw.color.violet.l30,
    backgroundImage: `url(${HomepageApiAndProInfoBackgroundMobile})`,
    backgroundPosition: "center",
    backgroundSize: "cover",

    display: "flex",
    flexDirection: "column",

    width: "100%",
    position: "relative",

    color: colours.white,
    overflow: "hidden",

    [ScreenSizes.xlAndAbove]: {
      backgroundImage: `url(${HomepageApiAndProInfoBackground})`,

      flexDirection: "row",

      padding: 0,
      gap: 0,
    },
  },
  sectionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    flexDirection: "column",
    padding: "6rem 0 4rem",
    ":first-child": {
      padding: "4rem 0 6rem",
    },

    [ScreenSizes.mdAndAbove]: {
      padding: "8rem 0 4rem",
      ":first-child": {
        padding: "4rem 0 8rem",
      },
    },

    [ScreenSizes.xlAndAbove]: {
      width: "50%",
      padding: "10rem 0",
      ":first-child": {
        padding: "10rem 0",
      },
    },
  },
  sectionWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",

    padding: "0 0.5rem",

    [ScreenSizes.lgAndAbove]: {
      padding: "0 6rem",
      marginBottom: "3rem",
      maxWidth: "60rem",
    },
  },
  title: {
    ...gStyles.avalonBold,

    fontSize: "2rem",
    textAlign: "center",

    margin: 0,
    marginTop: "1.75rem",
    marginBottom: "0.75rem",

    [ScreenSizes.lgAndAbove]: {
      marginTop: "2.75rem",
    },
  },
  description: {
    ...gStyles.fontRegular,
    fontSize: "1rem",
    lineHeight: 1.8,
    textAlign: "center",
    marginBottom: "2rem",
    [ScreenSizes.lgAndAbove]: {
      fontSize: "1.25rem",
      lineHeight: 1.6,
    },
  },
  divider: {
    display: "block",
    background: "white",
    position: "absolute",
    transformOrigin: "50% 50%",
    transform: "rotate(-3deg)",

    width: "140%",
    height: 40,
    marginTop: -20,
    left: "-10%",
    top: "50%",

    [ScreenSizes.lgAndAbove]: {
      transform: "rotate(-5deg)",
    },
    [ScreenSizes.xlAndAbove]: {
      width: 40,
      left: "50%",
      top: 0,
      marginLeft: -20,
      height: "160%",
      marginTop: "-10%",
      transform: "rotate(5deg)",
    },
  },
};

const BUTTON_STYLES = {
  button: {
    background: buttonTokens.color.background.api.default.default,
    color: colours.white,
    ":hover": {
      background: buttonTokens.color.background.api.hover.default,
    },
    ":active": {
      background: buttonTokens.color.background.api.active.default,
    },
    ":focus": {
      background: buttonTokens.color.background.api.active.default,
    },
  },
};

const PRO_BUTTON_STYLES = {
  button: {
    background: buttonTokens.color.background.primary.default.default,
    color: buttonTokens.color.text.primary.default.default,
  },
};

const HomepageApiAndProInfo = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.sectionContainer)}>
        <div className={css(styles.sectionWrapper)}>
          <img src={PodchaserApiLogo} alt="Podchaser Api Logo" height="44" />
          <h3 className={css(styles.title)}>
            The only podcast API you’ll ever need
          </h3>
          <p className={css(styles.description)}>
            Tap into the most comprehensive podcast database with a podcast API
            built specifically to drive discovery for listeners, podcasters, and
            brands.
          </p>
        </div>
        <HomepageSubtleButton
          label="Get API Access"
          buttonStyles={BUTTON_STYLES}
          variation="none"
          href="https://features.podchaser.com/api"
          onClick={() => {
            sendGAEvent({
              action: "homepageButtonClick",
              label: "getApiAccess",
              context: "Homepage",
            });
          }}
        />
      </div>
      <div className={css(styles.sectionContainer)}>
        <div className={css(styles.sectionWrapper)}>
          <img
            src={PodchaserProLogo}
            alt="Podchaser Pro Logo"
            title="Podchaser - Follow, Share and Rate Podcasts"
            height="44"
          />
          <h3 className={css(styles.title)}>
            Powerful podcast insights and contacts
          </h3>
          <p className={css(styles.description)}>
            Pull back the curtain on the most valuable data and information
            about podcasts. Trusted by hundreds of agencies, brands, and media
            companies.
          </p>
        </div>
        <HomepageProButton
          label="Get Pro Access"
          buttonStyles={PRO_BUTTON_STYLES}
          variation="pink"
        />
      </div>
      <span className={css(styles.divider)}></span>
    </div>
  );
};

export default HomepageApiAndProInfo;
