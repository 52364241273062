import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";

import StandardButton from "components/Buttons/StandardButton";

import mergeDeep from "utils/objects/mergeDeep";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  icon: {
    display: "flex",
    flexDirection: "column",

    marginLeft: "0.75rem",
  },
};

const BUTTON_STYLES = {
  button: {
    ...gStyles.avalonBold,
    fontSize: "1rem",

    margin: 0,

    width: "fit-content",

    paddingLeft: "1.75rem",
    paddingRight: "1.75rem",
  },
};

const HomepageSubtleButton = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  const { label, buttonStyles = {}, variation = "white", ...rest } = props;
  return (
    <StandardButton
      label={
        <Fragment>
          {label}
          <FontAwesomeIcon className={css(styles.icon)} icon={faArrowRight} />
        </Fragment>
      }
      variation={variation}
      flat
      overwriteStyles={mergeDeep(BUTTON_STYLES, buttonStyles)}
      {...rest}
    />
  );
};

export default HomepageSubtleButton;
