import rawTokens from "../../styles/tokens/tokens-raw";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: colours.white,
    padding: "2.5rem",

    [ScreenSizes.lgAndAbove]: {
      padding: "4rem 2.5rem 4rem",
    },

    [createBreakpoint({ min: 1400 })]: {
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    width: "100%",

    [ScreenSizes.lgAndAbove]: {
      flexDirection: "row",
      alignSelf: "center",

      maxWidth: "75rem",
      gap: "4rem",
    },
  },
  title: {
    ...gStyles.avalonBold,

    fontSize: "2.5rem",

    margin: 0,
    marginBottom: "2.5rem",
  },
  subTitle: {
    ...gStyles.avalonBold,

    fontSize: "0.875rem",
    color: rawTokens.raw.color.violet.l50,

    marginBottom: "0.75rem",
  },
  textArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [ScreenSizes.xlAndAbove]: {
      width: "50%",
      order: "initial",
    },
  },
};

export const HomepageTextSubTitle = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  return <p className={css(styles.subTitle)}>{props.children}</p>;
};

export const HomepageTextTitle = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  return <h4 className={css(styles.title)}>{props.children}</h4>;
};

export const HomepageTextArea = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  return <div className={css(styles.textArea)}>{props.children}</div>;
};

export const HomepageFeatureRow = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.wrapper)}>{props.children}</div>
    </div>
  );
};
