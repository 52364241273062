import { useContext } from "react";
import { useLocalStorage } from "usehooks-ts";

import RequestContext from "pages/RequestContext";

import { LOCALSTORAGE_TOKEN_KEY } from "constants/base";

export default function useCookie() {
  const { server, hasAuthCookieDefined } = useContext(RequestContext);

  const [localStorageAuth] = useLocalStorage(LOCALSTORAGE_TOKEN_KEY, null);

  return server ? hasAuthCookieDefined : !!localStorageAuth;
}
