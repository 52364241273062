import { HomepageCheckList, HomepageCheckListEntry } from "./HomepageCheckList";
import {
  HomepageTextSubTitle,
  HomepageTextTitle,
  HomepageTextArea,
  HomepageFeatureRow,
} from "./HomepageFeatureRow";
import HomepageFeatureRowImage from "./HomepageFeatureRowImage";
import HomepageSubtleButton from "./HomepageSubtleButton";
import HomepageBestPodcastImage from "./Images/PodcastSearchFiltersPreview.png";
import HomepageBestPodcastImage2x from "./Images/PodcastSearchFiltersPreview@2x.png";

import sendGAEvent from "utils/sendGAEvent";

const HomepageBestPodcast = () => {
  return (
    <HomepageFeatureRow>
      <HomepageFeatureRowImage
        src={HomepageBestPodcastImage}
        src2x={HomepageBestPodcastImage2x}
        alt="Find the best podcasts"
      />
      <HomepageTextArea>
        <HomepageTextSubTitle>Podcast Directory</HomepageTextSubTitle>
        <HomepageTextTitle>Find the best podcasts</HomepageTextTitle>
        <HomepageCheckList>
          <HomepageCheckListEntry
            title="Search and browse"
            subTitle="More than 5.5 million podcasts ready to be discovered."
          />
          <HomepageCheckListEntry
            title="Apple & Spotify Charts"
            subTitle="Daily podcast charts by country and category."
          />
          <HomepageCheckListEntry
            title="Curated lists"
            subTitle="Organized and annotated collections of podcasts or episodes."
          />
        </HomepageCheckList>
        <HomepageSubtleButton
          label="Start Browsing"
          link="/podcasts"
          onClick={() => {
            sendGAEvent({
              action: "homepageButtonClick",
              label: "startBrowsing",
              context: `Homepage`,
            });
          }}
        />
      </HomepageTextArea>
    </HomepageFeatureRow>
  );
};

export default HomepageBestPodcast;
