import ScreenSizes from "../../styles/ScreenSizes";
import tokens from "../../styles/tokens/tokens";
import { HomepageCheckList, HomepageCheckListEntry } from "./HomepageCheckList";
import {
  HomepageTextSubTitle,
  HomepageTextTitle,
  HomepageTextArea,
  HomepageFeatureRow,
} from "./HomepageFeatureRow";
import HomepageFeatureRowImage from "./HomepageFeatureRowImage";
import HomepageSubtleButton from "./HomepageSubtleButton";
import HomepageSponsorsImage from "./Images/PodcastSponsorMentionsPreview.png";
import HomepageSponsorsImage2x from "./Images/PodcastSponsorMentionsPreview@2x.png";

import sendGAEvent from "utils/sendGAEvent";

const CARD_STYLES_RIGHT = {
  container: {
    borderTop: `1px solid ${tokens.color.border.default}`,
  },
  wrapper: {
    [ScreenSizes.lgAndAbove]: {
      flexDirection: "row-reverse",
    },
  },
};

const HomepageTrack = (props) => {
  return (
    <HomepageFeatureRow styles={CARD_STYLES_RIGHT}>
      <HomepageFeatureRowImage
        src={HomepageSponsorsImage}
        src2x={HomepageSponsorsImage2x}
        alt="Track Features"
      />
      <HomepageTextArea>
        <HomepageTextSubTitle>Podcast Monitoring</HomepageTextSubTitle>
        <HomepageTextTitle>Track the latest mentions</HomepageTextTitle>
        <HomepageCheckList>
          <HomepageCheckListEntry
            title="Transcript mention alerts"
            subTitle="Get notified when keywords, names, or brands are mentioned in podcast episodes."
          />
          <HomepageCheckListEntry
            title="Podcast sponsors"
            subTitle="See who’s spending, for how long, with spend estimates for 15k sponsors."
          />
          <HomepageCheckListEntry
            title="Leverage those insights"
            subTitle="An organized way to understand who’s saying what on podcasts."
          />
        </HomepageCheckList>
        <HomepageSubtleButton
          label="Create an Alert"
          link="/alerts"
          onClick={() => {
            sendGAEvent({
              action: "homepageButtonClick",
              label: "createAlert",
              context: "Homepage",
            });
          }}
        />
      </HomepageTextArea>
    </HomepageFeatureRow>
  );
};

export default HomepageTrack;
