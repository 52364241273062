import Loadable from "@loadable/component";

const OAuthPrompt = Loadable(() =>
  import(/* webpackChunkName: "OAuthPrompt" */ "./OAuthPrompt")
);

function OAuthPromptLoading(props) {
  return null;
}

const OAuthPromptContainer = (props) => {
  return <OAuthPrompt {...props} fallback={<OAuthPromptLoading />} />;
};

export default OAuthPromptContainer;
