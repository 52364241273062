import rawTokens from "../../styles/tokens/tokens-raw";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  listContainer: {
    display: "flex",
    gap: "0.5rem",

    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  moreText: {
    color: rawTokens.raw.color.violet.l40,

    backgroundColor: "none",
    border: "none",

    ":focus": {
      backgroundColor: "none",
    },
  },
  label: {
    ...gStyles.avalonBold,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    borderRadius: 20,

    height: "2rem",
    fontSize: ".875rem",
    padding: "0 1rem",

    textWrap: "nowrap",

    backgroundColor: rawTokens.raw.color.violet.l90,
    color: rawTokens.raw.color.violet.l40,
  },
};

const HomepageNavLink = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.listContainer)}>
      <span className={css(styles.label)}>Demographics</span>
      <span className={css(styles.label)}>Reach</span>
      <span className={css(styles.label)}>Power Score™</span>
      <span className={css(styles.label)}>Contacts</span>
      <span className={css(styles.label)}>Brand Safety</span>
      <span className={css(styles.label)}>Transcripts</span>
      <span className={css(styles.label)}>Sponsor History</span>
      <span className={css(styles.label)}>Host & Guest Credits</span>
      <span className={css(styles.label)}>Chart Rankings</span>
      <span className={css(styles.label, styles.moreText)}>and more!</span>
    </div>
  );
};

export default HomepageNavLink;
