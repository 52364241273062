import colours from "./colours";
import tokens from "./tokens/tokens";
import buttonTokens from "./tokens/tokens-button";

import generateTransition from "utils/generateTransition";

import fontStyles from "styles/FontStyles";
import getHoverQuery from "styles/getHoverQuery";
import ScreenSizes from "styles/ScreenSizes";

const buttonStyle = {
  ...fontStyles.fontBold,
  background: colours.primaryTextBackground,
  border: "none",
  boxShadow: "none",
  width: "100%",
  color: colours.white,
  padding: "1em 1.6em 0.9em",
  fontSize: "0.7em",
  cursor: "pointer",
  transition: generateTransition({ target: "visual", speed: "150ms" }),

  ...getHoverQuery({
    background: colours.primaryHighlight,
  }),
  ":active": {
    outline: "none",
  },
};

const ellipsisStyles = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  minWidth: 0, // Attempt to fix some truncation issues (usually inside flex)
};

const noEllipsisStyles = {
  overflow: "initial",
  textOverflow: "initial",
  whiteSpace: "initial",
};

const gStyles = {
  ...fontStyles,
  noUl: {
    padding: 0,
    margin: 0,
    listStyle: "none",
  },
  noLi: {
    padding: 0,
    margin: 0,
    listStyle: "none",
  },
  noSidePadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  siteHeaderPaddingTop: {
    paddingTop: 106, // Site header + Site subheader

    [ScreenSizes.mdOnly]: {
      paddingTop: 106, // Site header + Site subheader,
    },
    [ScreenSizes.lgOnly]: {
      paddingTop: 110, // Site header + Site subheader,
    },
    [ScreenSizes.xlAndAbove]: {
      paddingTop: 110, // Site header + Site subheader,
    },
  },
  primaryPageTitle: {
    ...fontStyles.avalonBold,
    color: "white",
    textAlign: "center",
    fontSize: "2.5rem",
    position: "relative",
    width: "100%",
    maxWidth: "65%",
    padding: "0 2rem",
    margin: "0 auto 0.8rem",
    display: "block",

    [ScreenSizes.xlAndBelow]: {
      fontSize: "2em",
      maxWidth: "80%",
    },
    [ScreenSizes.lgAndBelow]: {
      maxWidth: "95%",
    },
    [ScreenSizes.mdAndBelow]: {
      fontSize: "1.75em",
      marginBottom: "0.5em",
      maxWidth: "100%",
    },
    [ScreenSizes.smAndBelow]: {
      fontSize: "1.5em",
    },
    [ScreenSizes.xsAndBelow]: {
      fontSize: "1.375em",
    },
  },
  ul: {
    display: "block",
    listStylePosition: "inside",
    listStyleType: "disc",
    marginLeft: "0.8em",
  },
  li: {
    display: "list-item",
    listStylePosition: "inside",
    listStyleType: "disc",
  },
  paragraph: {
    margin: "0 0 1.5em",
    padding: 0,
    ":last-child": {
      marginBottom: 0,
    },
  },
  link: {
    color: colours.primary,

    ...getHoverQuery({
      textDecoration: "underline",
    }),
  },
  foregroundDropShadow: {
    boxShadow: "0 0.2em 0.5em rgba(0,0,0,0.3), 0 0.03em 0.03em rgba(0,0,0,0.5)",
  },
  softShadow: {
    boxShadow: "0 0.2em 0.3em rgba(0,0,0,0.2)",
  },
  contentContainer: {
    paddingTop: "1rem",
    paddingRight: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "1rem",
    [ScreenSizes.xsOnly]: {
      padding: 10,
    },
    [ScreenSizes.mdOnly]: {
      padding: "1.5rem",
    },
    [ScreenSizes.lgOnly]: {
      padding: "2.2rem",
    },
    [ScreenSizes.xlAndAbove]: {
      padding: "3rem",
    },
  },
  contentContainerNoVerticalPadding: {
    paddingRight: "1rem",
    paddingLeft: "1rem",
    [ScreenSizes.xsOnly]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    [ScreenSizes.mdOnly]: {
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
    },
    [ScreenSizes.lgOnly]: {
      paddingLeft: "2.2rem",
      paddingRight: "2.2rem",
    },
    [ScreenSizes.xlAndAbove]: {
      paddingLeft: "3rem",
      paddingRight: "3rem",
    },
  },
  contentContainerHorizontal: {
    paddingRight: "1rem",
    paddingLeft: "1rem",
    [ScreenSizes.xsOnly]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    [ScreenSizes.mdOnly]: {
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
    },
    [ScreenSizes.lgOnly]: {
      paddingLeft: "2.2rem",
      paddingRight: "2.2rem",
    },
    [ScreenSizes.xlAndAbove]: {
      paddingLeft: "3rem",
      paddingRight: "3rem",
    },
  },
  negateContentContainer: {
    [ScreenSizes.lgAndBelow]: {
      margin: "-1rem",
    },
    [ScreenSizes.xsOnly]: {
      margin: -10,
    },
  },
  contentBox: {
    backgroundColor: colours.white,
    boxShadow: "none",
    transition: generateTransition({ target: "visual", speed: "300ms" }),
    position: "relative",
    outline: "none",
    ":focus": {
      outline: "none",
    },
    ":active": {
      outline: "none",
    },
  },
  contentBoxFocusable: {
    ":focus": {
      borderColor: "rgba(0,0,0,0.2)",
    },
  },
  headerFilterButton: {
    ...fontStyles.avalonBold,

    boxShadow: "none",
    transition: generateTransition({ target: "visual", speed: "300ms" }),
    position: "relative",
    padding: 0,
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    fontSize: "0.875rem",
    height: "2.5rem",
    outline: "none",
    textAlign: "left",
    borderRadius: "4em",
    lineHeight: 1,
    appearance: "normal",
    cursor: "pointer",

    border: `1px solid ${buttonTokens.color.border.outer.secondary.default.default}`,
    backgroundColor: buttonTokens.color.background.secondary.default.default,
    color: buttonTokens.color.text.secondary.default.default,

    ":hover": {
      borderColor: buttonTokens.color.border.outer.secondary.hover.default,
      backgroundColor: buttonTokens.color.background.secondary.hover.default,
      color: buttonTokens.color.text.secondary.hover.default,
    },
    ":focus": {
      outline: "none",
      borderColor: buttonTokens.color.border.outer.secondary.active.default,
      backgroundColor: buttonTokens.color.background.secondary.active.default,
      color: buttonTokens.color.text.secondary.active.default,
    },
    ":active": {
      outline: "none",
      borderColor: buttonTokens.color.border.outer.secondary.active.default,
      backgroundColor: buttonTokens.color.background.secondary.active.default,
      color: buttonTokens.color.text.secondary.active.default,
    },
  },
  headerFilterButtonContent: {
    flex: "1 1 100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 1rem",
  },
  headerFilterButtonIcon: {
    fontSize: "0.875rem",
    display: "block",
    maxWidth: "1.25em",
    textAlign: "center",
    flex: "0 0 auto",
    marginRight: "0.5rem",
  },
  headerFilterButtonLabel: {
    flex: "0 1 100%",
    display: "flex",
    paddingRight: "0.5rem",
    whiteSpace: "nowrap",
    ":last-child": {
      paddingRight: 0,
    },
  },
  headerFilterButtonSelected: {
    borderColor: buttonTokens.color.border.outer.selected.default.default,
    backgroundColor: buttonTokens.color.background.selected.default.default,
    color: buttonTokens.color.text.selected.default.default,

    ":hover": {
      borderColor: buttonTokens.color.border.outer.selected.hover.default,
      backgroundColor: buttonTokens.color.background.selected.hover.default,
      color: buttonTokens.color.text.selected.hover.default,
    },
    ":focus": {
      borderColor: buttonTokens.color.border.outer.selected.active.default,
      backgroundColor: buttonTokens.color.background.selected.active.default,
      color: buttonTokens.color.text.selected.active.default,
    },
    ":active": {
      borderColor: buttonTokens.color.border.outer.selected.active.default,
      backgroundColor: buttonTokens.color.background.selected.active.default,
      color: buttonTokens.color.text.selected.active.default,
    },
  },
  label: {
    ...fontStyles.fontLight,
    fontSize: "0.7em",
    color: colours.bodyText,
    textTransform: "uppercase",
  },
  star: {
    fontSize: "1.2rem",
    marginLeft: "0.1em",
    marginRight: "0.1em",
  },
  highlight: {
    color: colours.primary,
  },
  mainSectionTitle: {
    ...fontStyles.fontSemiBold,
    fontSize: "1rem",
    color: colours.greyishBlueDarker,
    textTransform: "uppercase",
    letterSpacing: "0.05em",
    lineHeight: "1",
    [ScreenSizes.mdAndBelow]: {
      flex: "0 0 100%",
      marginBottom: "0.8rem",
    },
    display: "block",
    marginBottom: "0.5rem",
  },
  archivePageTitle: {
    ...fontStyles.avalonBold,
    // color: colours.bodyText,
    lineHeight: 1.3,
    margin: 0,
    padding: 0,
    flex: "0 1 100%",
    fontSize: "1.5rem",

    [ScreenSizes.lgAndAbove]: {
      fontSize: "1.75rem",
      flex: "1 1 auto",
    },
  },
  button: {
    ...buttonStyle,
  },
  buttonRounded: {
    borderRadius: 300, // TODO: This can probably be part of buttonStyle now, or soon
  },
  buttonSecondary: {
    background: colours.oldSecondary,

    ...getHoverQuery({
      background: colours.oldSecondaryHighlight,
    }),
  },
  buttonInlineBlock: {
    [ScreenSizes.lgAndAbove]: {
      display: "inline-block",
      width: "auto",
    },
  },
  sectionMoreLink: {
    ...fontStyles.fontSemiBold,
    maxWidth: "15rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "0.69rem",
    cursor: "pointer",
    marginBottom: "1rem",
    boxShadow: "none",
    color: "#000",
    border: `1px solid ${colours.lightGreyBorder}`,
    padding: "1.25em 1.5em 1em",
    borderRadius: 300,

    ":hover": {
      color: "#333",
    },
  },
  textEllipsis: {
    ...ellipsisStyles,
  },
  noEllipsis: {
    ...noEllipsisStyles,
  },
  resetButton: {
    margin: 0,
    padding: 0,
    border: "none",
    appearance: "normal",
    backgroundColor: "transparent",
    cursor: "pointer",
    display: "inline",

    ":hover": {
      textDecoration: "none",
      outline: "none",
    },
    ":focus": {
      textDecoration: "none",
      outline: "none",
    },
  },
  noButton: {
    appearance: "normal",
    border: "none",
    borderRadius: 0,
    fontFamily: "inherit",
    fontSize: "100%",
    lineHeight: 1.15,
    margin: 0,
    padding: 0,
    overflow: "visible",
    textTransform: "none",

    ":active": {
      textDecoration: "none",
      outline: "none",
      border: "none",
    },
    ":focus": {
      textDecoration: "none",
      outline: "none",
      border: "none",
    },
  },
  floatBubble: {
    zIndex: 1100,
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0px 2px 11.5px 0 rgba(0, 0, 0, 0.25)",
  },
  gradientBackground: {
    backgroundSize: "cover",
    backgroundPosition: "center center",
    background: colours.mainHeaderBackground,
  },
  newBackgroundItemColor: {
    background: "#2a0564",
  },
  blurBehind: {
    backdropFilter: "blur(3px)",
  },
  headerWrapper: {
    padding: "2.5rem 0.7em", // (no headers)
    position: "relative",
    backgroundPosition: "center center",

    [ScreenSizes.lgAndAbove]: {
      paddingTop: "10.375em", // 3.5em + 38px (sub header) + 72 (header)
      paddingBottom: "3em",
    },
    [ScreenSizes.xlAndAbove]: {
      paddingTop: "10.375em", // '3.5em', 3.5*16 + 38px (sub header) + 72 (header)
      paddingBottom: "3.25em",
    },
  },
  headerSearchWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    minWidth: "48%",
    margin: "1.5rem 0",
  },
  headerSearchStyles: {
    icon: {
      fontSize: "1.2em",
    },
    search: {
      maxWidth: "23.6rem",
    },
  },
  chromaticAbberationBlock: {
    boxShadow:
      "3px 3px 0px rgb(255, 239, 132), 3px -3px 0px rgb(239, 50, 128), -3px 3px 0px rgb(60, 208, 238)",
    backgroundColor: "#fff",
  },
  chromaticAbberationBlockThin: {
    boxShadow:
      "2px 2px 0px rgb(255, 239, 132), 2px -2px 0px rgb(239, 50, 128), -2px 2px 0px rgb(60, 208, 238)",
    backgroundColor: "#fff",
  },
  chromaticAbberationBlockThinnest: {
    boxShadow:
      "1px 1px 0px rgb(255, 239, 132), 1px -1px 0px rgb(239, 50, 128), -1px 1px 0px rgb(60, 208, 238)",
    backgroundColor: "#fff",
  },

  bodySubHeading: {
    color: "var(--color-neutral-d4)",
    fontSize: "1.25rem",
    ...fontStyles.fontBold,
  },

  integrationStatusNotIntegratedBubble: {
    background: colours.integrationStatusNotIntegratedLight,
    color: colours.integrationStatusNotIntegratedDark,
  },
  integrationStatusIntegratedBubble: {
    background: colours.integrationStatusIntegratedLight,
    color: colours.integrationStatusIntegratedDark,
  },
  integrationStatusInProgressBubble: {
    background: colours.integrationStatusInProgressLight,
    color: colours.integrationStatusInProgressDark,
  },
  integrationStatusPartialBubble: {
    background: colours.integrationStatusPartialLight,
    color: colours.integrationStatusPartialDark,
  },

  skeletonPlaceholder: {
    backgroundColor: colours.offWhite,
    borderRadius: 8,
    animationDuration: "900ms",
    animationIterationCount: "infinite",
    animationTimingFunction: "ease-in-out",
    animationName: [
      {
        "0%": {
          boxShadow: `inset 0 0 5px ${colours.offWhite}`,
        },
        "40%": {
          boxShadow: `inset 0 0 15px ${colours.lightGrey}`,
        },
        "100%": {
          boxShadow: `inset 0 0 5px ${colours.offWhite}`,
        },
      },
    ],
  },

  // Sidebar
  sidebarSection: {
    marginBottom: "2rem",
    width: "100%",
  },
  sidebarHeading: {
    ...fontStyles.fontBold,
    fontSize: "0.7rem",
    margin: "0 0 7px 0",
    padding: 0,
  },
  claimedGradient: (direction = 324) =>
    `linear-gradient(${direction}deg, ${tokens.color.claimed.gradient.l0.default}, ${tokens.color.claimed.gradient.l100.default})`, // #FF227B, #9E40E4)`,
  strongNewSecondaryGradient: (direction) =>
    `linear-gradient(${direction}deg, #380574, #5e2596)`,
  vibrantBrandGradient: (direction) =>
    `linear-gradient(${direction}deg, #FF227B, #9E40E4)`,
  subtleGreyGradient: (direction) =>
    `linear-gradient(${direction}deg, #FFF, #EEE)`,
  lightConnectGradient: (direction) =>
    `linear-gradient(${direction}deg, #FE92C4 0%, #CFA5FB 50%, #95D1FA 100%)`,
  proGradient: (direction) =>
    `linear-gradient(${direction}deg, #301d64, #922a9e)`,
};

export const customContentContainerStyles = (styles) => ({
  ...gStyles.contentContainer,
  ...styles,
  [ScreenSizes.xsOnly]: {
    ...gStyles.contentContainer[ScreenSizes.xsOnly],
    ...styles,
  },
  [ScreenSizes.mdOnly]: {
    ...gStyles.contentContainer[ScreenSizes.mdOnly],
    ...styles,
  },
  [ScreenSizes.lgOnly]: {
    ...gStyles.contentContainer[ScreenSizes.lgOnly],
    ...styles,
  },
  [ScreenSizes.xlAndAbove]: {
    ...gStyles.contentContainer[ScreenSizes.xlAndAbove],
    ...styles,
  },
});

export default gStyles;
