import ScreenSizes from "../../styles/ScreenSizes";
import tokens from "../../styles/tokens/tokens";
import { HomepageCheckList, HomepageCheckListEntry } from "./HomepageCheckList";
import {
  HomepageTextSubTitle,
  HomepageTextTitle,
  HomepageTextArea,
  HomepageFeatureRow,
} from "./HomepageFeatureRow";
import HomepageFeatureRowImage from "./HomepageFeatureRowImage";
import HomepageSubtleButton from "./HomepageSubtleButton";
import HomepageProInsightsImage from "./Images/PodcastProInsightsPreview.png";
import HomepageProInsightsImage2x from "./Images/PodcastProInsightsPreview@2x.png";

import sendGAEvent from "utils/sendGAEvent";

const CARD_STYLES_RIGHT = {
  container: {
    borderTop: `1px solid ${tokens.color.border.default}`,
    borderBottom: `1px solid ${tokens.color.border.default}`,
  },
  wrapper: {
    [ScreenSizes.lgAndAbove]: {
      flexDirection: "row-reverse",
    },
  },
};

const HomepageProInsights = (props) => {
  return (
    <HomepageFeatureRow styles={CARD_STYLES_RIGHT}>
      <HomepageFeatureRowImage
        src={HomepageProInsightsImage}
        src2x={HomepageProInsightsImage2x}
        alt="Pro Insights Features"
      />
      <HomepageTextArea>
        <HomepageTextSubTitle>Podcast Media Database</HomepageTextSubTitle>
        <HomepageTextTitle>Valuable Pro Insights</HomepageTextTitle>
        <HomepageCheckList>
          <HomepageCheckListEntry
            title="Reach"
            subTitle="Monthly and per episode reach estimates so you can understand audience size."
          />
          <HomepageCheckListEntry
            title="Demographics"
            subTitle="Age, gender, income, interests, job titles, geography so you can reach right listeners."
          />
          <HomepageCheckListEntry
            title="Contacts"
            subTitle="2M+ validated contacts broken out by role so you can reach the right people."
          />
        </HomepageCheckList>
        <HomepageSubtleButton
          label="Learn More"
          href="https://features.podchaser.com/pro"
          onClick={() => {
            sendGAEvent({
              action: "homepageButtonClick",
              label: "learnMore",
              context: `Homepage`,
            });
          }}
        />
      </HomepageTextArea>
    </HomepageFeatureRow>
  );
};

export default HomepageProInsights;
