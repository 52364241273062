import { StyleSheet } from "aphrodite";
import PropTypes from "prop-types";
import { forwardRef, memo, useMemo } from "react";

import AbstractButton from "components/Buttons/AbstractButton";

import gStyles from "../../styles/GenericStyles";

import generateTransition from "utils/generateTransition";
import withHover from "utils/react-with-hover";

import colours from "styles/colours";

export const BUTTON_VARIATIONS = {
  actioned: "actioned",
  blue: "blue",
  green: "green",
  pink: "pink",
};

const variationBaseStyles = {
  transparent: StyleSheet.create({
    default: {
      backgroundColor: "transparent",
      boxShadow: "none",
      border: "none",

      ":focus": {
        backgroundColor: "var(--color-neutral-l1)",
        border: "none",
      },
    },
    hover: {
      ":hover": {
        backgroundColor: "var(--color-neutral-l1)",
        boxShadow: "none",
        border: "none",
      },
    },
    active: {
      boxShadow: "none",
      border: "none",
    },
    activeHover: {
      ":hover": {
        boxShadow: "none",
        border: "none",
      },
    },
    disabled: {
      boxShadow: "none",
      border: "none",
      color: "var(--color-neutral-l5)",
      cursor: "not-allowed",
    },
  }),
  clear: StyleSheet.create({
    default: {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
      transition: generateTransition({
        targets: ["filter"],
      }),

      ":focus": {
        backgroundColor: "transparent",
        borderColor: "transparent",
        boxShadow: "none",
        filter: "opacity(0.8)",
      },
    },
    hover: {
      ":hover": {
        backgroundColor: "transparent",
        borderColor: "transparent",
        boxShadow: "none",
      },
    },
    active: {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
      filter: "opacity(0.6)",
    },
  }),
  blue: StyleSheet.create({
    default: {
      backgroundColor: "var(--color-secondary-d4)",
      color: "white",

      ":focus": {
        backgroundColor: "var(--color-secondary-d5)",
        color: "white",
      },
    },
    hover: {
      ":hover": {
        backgroundColor: "var(--color-secondary-d5)",
      },
    },
    active: {
      backgroundColor: "var(--color-secondary-d3)",
    },
    activeHover: {},
    disabled: {
      backgroundColor: "var(--color-neutral-d6)",
      color: "rgba(240, 240, 240, 0.8)",
      cursor: "not-allowed",
    },
  }),
  green: StyleSheet.create({
    default: {
      backgroundColor: colours.positive,
      color: "white",

      ":focus": {
        backgroundColor: colours.positive,
        borderColor: colours.positiveFocusBorder,
      },
    },
    hover: {
      ":hover": {
        backgroundColor: colours.positiveHighlight,
      },
    },
    active: {
      backgroundColor: colours.positiveInset,
    },
    activeHover: {},
    disabled: {
      backgroundColor: colours.positiveDarker,
      color: "rgba(240, 240, 240, 0.8)",
      cursor: "not-allowed",
    },
  }),
  actioned: StyleSheet.create({
    default: {
      backgroundColor: "white",
      borderColor: colours.actioned,
      boxShadow: "none",
      color: colours.actioned,

      ":focus": {
        borderColor: colours.actionedHighlight,
        boxShadow: "none",
      },
    },
    hover: {
      ":hover": {
        backgroundColor: "white",
        borderColor: colours.actionedHighlight,
        boxShadow: "none",
      },
    },
    active: {
      boxShadow: "none",
    },
    activeHover: {},
    disabled: {
      backgroundColor: colours.actionedLowlight,
      color: "rgba(240, 240, 240, 0.8)",
      cursor: "not-allowed",
    },
  }),
  red: StyleSheet.create({
    default: {
      backgroundColor: "white",
      color: "#D96565",

      ":focus": {
        backgroundColor: "#e08e8e",
        borderColor: "#D97373",
      },
    },
    hover: {
      ":hover": {
        backgroundColor: "#D97373",
        color: "white",
      },
    },
    active: {
      borderColor: "transparent",
    },
    activeHover: {},
    disabled: {
      color: "rgba(203, 134, 134, 0.8)",
      cursor: "not-allowed",
    },
    flat: {
      border: "1px #DFE1E6 solid",
    },
    flatHover: {
      ":hover": {
        border: "1px #D97373 solid",
      },
    },
    bordered: {
      border: "1px #DFE1E6 solid",
      color: "#D96565",
    },
  }),
  pink: StyleSheet.create({
    default: {
      background: colours.primaryTextBackground,
      color: colours.white,

      ":hover": {
        backgroundColor: colours.primaryLighter,
      },
      ":focus": {
        backgroundColor: colours.primaryInset,
        color: colours.white,
      },
    },
    hover: {
      ":hover": {
        background: colours.primaryLighter,
      },
    },
    active: {
      backgroundColor: colours.white,
      borderColor: colours.actioned,
      color: colours.actioned,
      boxShadow: "none",

      ":hover": {
        backgroundColor: colours.white,
        borderColor: colours.actionedHighlight,
        color: colours.actionedHighlight,
        boxShadow: "none",
      },
      ":focus": {
        backgroundColor: colours.white,
        borderColor: colours.actionedLowlight,
        color: colours.actionedLowlight,
        boxShadow: "none",
      },
    },
    activeHover: {},
    disabled: {
      backgroundColor: colours.primary,
      color: "rgba(255, 255, 255, 0.5)",
      cursor: "not-allowed",
    },
  }),
  negative: StyleSheet.create({
    default: {
      backgroundColor: colours.negative,
      color: "white",

      ":focus": {
        backgroundColor: colours.negativeLighter,
        color: "white",
      },
    },
    hover: {
      ":hover": {
        backgroundColor: colours.negativeLightDark,
      },
    },
    active: {
      backgroundColor: colours.negativeLighter,
    },
    activeHover: {},
    disabled: {
      backgroundColor: colours.negativeLighter,
      color: "rgba(240, 240, 240, 0.8)",
      cursor: "not-allowed",
    },
  }),
  white: StyleSheet.create({
    default: {
      backgroundColor: colours.white,
      color: colours.bodyText,
      borderColor: "var(--color-neutral-l2)",

      ":focus": {
        backgroundColor: colours.white,
        color: "var(--color-neutral-l3)",
        borderColor: "var(--color-neutral-l4)",
      },
    },
    hover: {
      ":hover": {
        backgroundColor: colours.white,
        borderColor: "var(--color-neutral-l4)",
      },
    },
    active: {
      borderColor: colours.actioned,
      backgroundColor: colours.actioned,
      boxShadow: "none",
      color: "white",

      ":focus": {
        backgroundColor: colours.actioned,
        borderColor: colours.actionedHighlight,
        boxShadow: "none",
      },
    },
    activeHover: {
      ":hover": {
        backgroundColor: colours.actionedHighlight,
        borderColor: colours.actionedHighlight,
        boxShadow: "none",
        color: "white",
      },
    },
    disabled: {
      backgroundColor: "#fff",
      color: "rgba(0,0,0,0.3)",
      cursor: "not-allowed",
      ":hover": {
        boxShadow: "none",
      },
    },
    flat: {
      border: "1px #DFE1E6 solid",
    },
  }),
  whiteNegative: StyleSheet.create({
    default: {
      color: colours.negative,

      ":focus": {
        backgroundColor: "#F6F6F6",
      },
    },
    hover: {
      ":hover": {
        backgroundColor: "#F6F6F6",
      },
    },
    active: {
      backgroundColor: colours.actioned,
      borderColor: colours.actioned,
      boxShadow: "none",
      color: "white",
    },
    activeHover: {
      ":hover": {
        backgroundColor: colours.actioned,
        borderColor: colours.actioned,
        boxShadow: "none",
        color: "white",
      },
    },
    disabled: {
      backgroundColor: "#f7f7f7",
      color: "rgba(41, 59, 101, .5)",
      cursor: "not-allowed",
    },
    flat: {
      border: "1px #DFE1E6 solid",
    },
  }),
  subtle: StyleSheet.create({
    default: {
      backgroundColor: colours.cardSubtitleText,
      color: "white",
      ":focus": {
        backgroundColor: "#97a4bd",
      },
    },
    hover: {
      ":hover": {
        backgroundColor: "#97a4bd",
      },
    },
    active: {
      backgroundColor: "#7c879c",
    },
    activeHover: {},
    disabled: {
      backgroundColor: "#f7f7f7",
      color: "rgba(41, 59, 101, .5)",
      cursor: "not-allowed",
    },
  }),
  darkSubtle: StyleSheet.create({
    default: {
      backgroundColor: "rgba(255,255,255,0.2)",
      color: "white",
      ":focus": {
        backgroundColor: "rgba(255,255,255,0.3)",
      },
    },
    hover: {
      backgroundColor: "rgba(255,255,255,0.3)",
    },
    active: {
      backgroundColor: "rgba(255,255,255,0.15)",
    },
    activeHover: {},
    disabled: {
      color: "rgba(255, 255, 255, .35)",
      cursor: "default",
    },
  }),
  darkSuperSubtle: StyleSheet.create({
    default: {
      backgroundColor: "rgba(255,255,255,0)",
      color: "white",
      ":focus": {
        backgroundColor: "rgba(255,255,255,0.2)",
      },
    },
    hover: {
      backgroundColor: "rgba(255,255,255,0.2)",
    },
    active: {
      backgroundColor: "rgba(255,255,255,0.1)",
    },
    activeHover: {},
    disabled: {
      color: "rgba(255, 255, 255, .35)",
      cursor: "default",
    },
  }),
  none: StyleSheet.create({
    default: {},
    hover: {},
    active: {},
    activeHover: {},
    disabled: {},
  }),
};

const roundedStyles = StyleSheet.create({
  button: {
    borderRadius: 300,
  },
});

const StandardButton = forwardRef(
  (
    {
      active,
      ariaLabel,
      bordered,
      children,
      customStyles,
      dataId,
      disabled,
      flat,
      fontSize,
      height,
      hover,
      hoverLabel,
      label: passedLabel,
      minor,
      outerComponent,
      overwriteStyles,
      rounded,
      showDisabled,
      styles,
      submitting,
      variation,
      width,
      withPadding,
      borderRadius,
      ...props
    },
    ref
  ) => {
    const buttonStyles = useMemo(
      () => ({
        common: StyleSheet.create({
          button: {
            ...gStyles.avalonBold,
            color: colours.bodyText,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "none", // '0 4px 12px 0 rgba(0, 0, 0, 0.14)',
            borderRadius,
            paddingTop: "12px",
            paddingBottom: "11px",
            marginLeft: "auto",
            marginRight: "auto",
            border: "1px transparent solid",
            transition: generateTransition({
              targets: ["background", "color", "box-shadow"],
            }),
            ":focus": {
              borderColor: "rgba(0,0,0,0.1)",
            },
            ":hover": {
              transition:
                "background 200ms cubic-bezier(0.465, 0.183, 0.153, 0.946), " +
                "color 200ms cubic-bezier(0.465, 0.183, 0.153, 0.946), boxShadow " +
                "200ms cubic-bezier(0.465, 0.183, 0.153, 0.946)",
              borderColor: "rgba(0,0,0,0.1)",
              ...customStyles.hover,
            },
            fontSize,
            ...customStyles.button,
          },
          buttonWithPadding: {
            paddingLeft: "1.125em",
            paddingRight: "1.125em",
          },
          minorButton: {
            paddingTop: "6px",
            paddingBottom: "5px",
            width: "auto",
            paddingLeft: "1em",
            paddingRight: "1em",
          },
          disabled: {
            cursor: "not-allowed",
            ...customStyles.disabled,
          },
          active: {
            ...customStyles.active,
            ...(customStyles.activeHover
              ? { ":hover": customStyles.activeHover }
              : {}),
          },
          submitting: {
            ...customStyles.submitting,
          },
          flat: {
            boxShadow: "none",
            ...customStyles.flat,

            ":hover": {
              boxShadow: "0 3px 8px 0 rgba(0, 0, 0, 0.14)",
              ...customStyles.flatHover,
            },
          },
          flatDisabled: {
            boxShadow: "none",
            ...customStyles.flatHover,
          },
        }),
        ...variationBaseStyles,
      }),
      [borderRadius, customStyles, fontSize]
    );

    const variationStyles = useMemo(() => {
      if (!variation || !buttonStyles[variation]) {
        return [];
      }
      const toReturn = [buttonStyles[variation].default];

      if (
        !(disabled && showDisabled) &&
        !flat &&
        buttonStyles[variation].hover
      ) {
        toReturn.push(buttonStyles[variation].hover);
      }
      if (active && buttonStyles[variation].active) {
        toReturn.push(buttonStyles[variation].active);
      }
      if (
        active &&
        !(disabled && showDisabled) &&
        !flat &&
        buttonStyles[variation].activeHover
      ) {
        toReturn.push(buttonStyles[variation].activeHover);
      }
      if (disabled && showDisabled && buttonStyles[variation].disabled) {
        toReturn.push(buttonStyles[variation].disabled);
      }
      if (flat && buttonStyles[variation].flat) {
        toReturn.push(buttonStyles[variation].flat);
      }
      if (flat && buttonStyles[variation].flatHover) {
        toReturn.push(buttonStyles[variation].flatHover);
      }
      if (bordered && buttonStyles[variation].bordered) {
        toReturn.push(buttonStyles[variation].bordered);
      }

      return toReturn;
    }, [
      variation,
      disabled,
      showDisabled,
      flat,
      active,
      buttonStyles,
      bordered,
    ]);

    const label = hover && hoverLabel ? hoverLabel : passedLabel;
    const aphStyles = useMemo(
      () =>
        [
          buttonStyles.common.button,
          withPadding && buttonStyles.common.buttonWithPadding,
          minor && buttonStyles.common.minorButton,
          active && buttonStyles.common.active,
          submitting && buttonStyles.common.submitting,
          flat && buttonStyles.common.flat,
          disabled && showDisabled && buttonStyles.common.disabled,
          disabled && showDisabled && flat && buttonStyles.common.flatDisabled,
          ...variationStyles,
          rounded && roundedStyles.button,
          styles,
        ].filter((st) => !!st),
      [
        buttonStyles.common.button,
        buttonStyles.common.buttonWithPadding,
        buttonStyles.common.minorButton,
        buttonStyles.common.active,
        buttonStyles.common.submitting,
        buttonStyles.common.flat,
        buttonStyles.common.disabled,
        buttonStyles.common.flatDisabled,
        withPadding,
        minor,
        active,
        submitting,
        flat,
        disabled,
        showDisabled,
        variationStyles,
        rounded,
        styles,
      ]
    );

    // TODO: Split `aphStyles` so it can use better style functionality from AbstractButton, like hoverStyles, etc
    const button = (
      <AbstractButton
        {...props}
        submitting={submitting}
        disabled={disabled}
        ref={ref}
        outerComponent={outerComponent}
        aphStyles={aphStyles}
        overwriteStyles={overwriteStyles}
        label={label || children}
        ariaLabel={ariaLabel}
        dataId={dataId}
      />
    );

    return width ? (
      <div style={{ maxWidth: width, maxHeight: height }}>{button}</div>
    ) : (
      button
    );
  }
);

StandardButton.propTypes = {
  active: PropTypes.bool,
  ariaLabel: PropTypes.string,
  borderRadius: PropTypes.string,
  bordered: PropTypes.bool,
  children: PropTypes.any,
  customStyles: PropTypes.object,
  dataId: PropTypes.string,
  disabled: PropTypes.bool,
  flat: PropTypes.bool,
  fontSize: PropTypes.string,
  height: PropTypes.string,
  hover: PropTypes.bool,
  hoverLabel: PropTypes.node,
  label: PropTypes.any,
  minor: PropTypes.bool,
  outerComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.elementType,
  ]),
  overwriteStyles: PropTypes.object,
  rounded: PropTypes.bool,
  showDisabled: PropTypes.bool,
  styles: PropTypes.object,
  submitting: PropTypes.bool,
  variation: PropTypes.oneOf([
    "transparent",
    "actioned",
    "clear",
    "blue",
    "green",
    "red",
    "pink",
    "negative",
    "white",
    "whiteNegative",
    "subtle",
    "darkSubtle",
    "darkSuperSubtle",
    "none",
  ]),
  width: PropTypes.string,
  withPadding: PropTypes.bool,
};

StandardButton.defaultProps = {
  active: false,
  ariaLabel: null,
  bordered: false,
  children: null,
  customStyles: {},
  dataId: null,
  disabled: false,
  flat: false,
  fontSize: ".75rem",
  borderRadius: "4px",
  height: null,
  hover: false,
  hoverLabel: null,
  label: null,
  minor: false,
  overwriteStyles: {},
  rounded: true,
  showDisabled: true,
  styles: null,
  submitting: false,
  variation: "transparent",
  width: null,
  withPadding: false,
};

StandardButton.displayName = "StandardButton";

export default memo(
  withHover({
    containerStyle: {
      flex: 1,
      width: "100%",
      height: "auto",
    },
  })(StandardButton)
);
