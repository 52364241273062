import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import rawTokens from "../../styles/tokens/tokens-raw";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  listContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",

    marginBottom: "2.5rem",
  },
  listEntry: {
    display: "flex",
  },
  icon: {
    color: rawTokens.raw.color.violet.l50,
    width: "1.5rem",
    height: "1.5rem",

    marginRight: "0.75rem",
    marginTop: "0.35rem",
  },
  listEntryTitle: {
    ...gStyles.avalonBold,

    fontSize: "1.5rem",

    marginBottom: "0.5rem",
  },
  listEntrySubTitle: {
    ...gStyles.fontRegular,

    fontSize: "1rem",
    lineHeight: 1.6,
    marginBottom: "0.5rem",
  },
};

export function HomepageCheckListEntry(props) {
  const { title, subTitle } = props;
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <li>
      <div className={css(styles.listEntry)}>
        <FontAwesomeIcon className={css(styles.icon)} icon={faCheckCircle} />
        <div>
          <p className={css(styles.listEntryTitle)}>{title}</p>
          <p className={css(styles.listEntrySubTitle)}>{subTitle}</p>
        </div>
      </div>
    </li>
  );
}

export function HomepageCheckList(props) {
  const { children } = props;
  const { styles, css } = useStyles(baseStyles, props);

  return <ul className={css(styles.listContainer)}>{children}</ul>;
}
