import { List } from "immutable";

import removeNil from "../arrays/removeNil";

/**
 * Deeply merges a given set of objects together.
 *
 * Objects to the right take priority.
 *
 * @param  {...Object} args - The objects to merge.
 *
 * @return {Object} - The merged object.
 */
export default function mergeDeep(...args) {
  const filtered = removeNil(args);

  if (filtered.length < 1) {
    return {};
  }

  if (filtered.length === 1) {
    return args[0];
  }

  return filtered.reduce((acc, cur) => {
    Object.keys(cur).forEach((key) => {
      if (typeof acc[key] === "object" && typeof cur[key] === "object") {
        // eslint-disable-next-line no-param-reassign
        acc[key] = mergeDeep(acc[key], cur[key]);
      } else {
        // eslint-disable-next-line no-param-reassign
        acc[key] = cur[key];
      }
    });
    return acc;
  }, {});
}

// Merge functions to Immutable Objects
// https://github.com/immutable-js/immutable-js/issues/1452#issuecomment-386162309
const isMergeable = (a) =>
  a &&
  typeof a === "object" &&
  typeof a.mergeWith === "function" &&
  !List.isList(a);

export const mergeDeepOverwriteLists = (a, b) => {
  // If b is null, it would overwrite a, even if a is mergeable
  if (b === null) {
    return b;
  }

  if (isMergeable(a) && !List.isList(a)) {
    return a.mergeWith(mergeDeepOverwriteLists, b);
  }

  return b;
};
