import { useCallback } from "react";

import HomepageSubtleButton from "./HomepageSubtleButton";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";

const HomepageProButton = (props) => {
  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const onDemoRequestSubmitted = useCallback(() => {
    sendGAEvent({
      action: `submittedProLeadFormModal`,
      page: "Homepage",
      context: "ProLeadForm",
    });
  }, []);

  const onProButtonClick = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      sendGAEvent({
        action: `openedProLeadForm`,
        page: `Homepage`,
        context: "ProLeadForm",
        label: "getProAccess",
      });

      showModal("proLeadForm", {
        onDemoRequestSubmitted: onDemoRequestSubmitted,
      });
    },
    [onDemoRequestSubmitted, showModal]
  );

  return (
    <HomepageSubtleButton
      href="https://features.podchaser.com/pro"
      onClick={(e) => {
        onProButtonClick(e);
        return false;
      }}
      {...props}
    />
  );
};

export default HomepageProButton;
